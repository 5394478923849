/* eslint-disable camelcase */

export enum KpiValue {
  CPA = 'CPA',
  CPA_PC = 'CPA_PC',
  CPC = 'CPC',
  CTR = 'CTR',
  CPCV = 'CPCV',
  VTR = 'VTR',
  V_CPM = 'V-CPM',
  other = 'other',
  CPM = 'CPM',
  CPVideoView = 'CPVideoView',
  CPMbumper = 'CPMbumper',
  CPIAVC = 'CPIAVC',
  authentic_attention_index = 'authentic_attention_index',
  reach = 'reach',
  viewable_time = 'viewable_time',
  ViewTR = 'ViewTR',
  CPMr = 'CPMr',
  reach_and_frequency = 'reach_and_frequency',
  ROAS = 'roas',
  LTV = 'ltv',
  total_engagement_index = 'total_engagement_index',
  total_exposure_index = 'total_exposure_index',
  CPV = 'CPV'
}

export enum KpiText {
  CPA = 'CPA',
  CPA_PC = 'CPA_PC',
  CPC = 'CPC',
  CTR = 'CTR',
  CPCV = 'CPCV',
  VTR = 'VTR - Video Only',
  'V-CPM' = 'V-CPM',
  other = 'other',
  CPM = 'CPM',
  CPVideoView = 'CPV (Skippable)',
  CPMbumper = 'CPM (Bumper, Non-Skip)',
  CPIAVC = 'CPIAVC',
  authentic_attention_index = 'Attention Index : DV Authentic Attention®',
  reach = 'Reach',
  CPMr = 'CPMr',
  viewable_time = 'Viewable Time (DoubleVerify)',
  ViewTR = 'VTR - Display Only',
  reach_and_frequency = 'Reach And Frequency',
  roas = 'ROAS',
  ltv = 'LTV',
  total_engagement_index = 'DV Engagement Index',
  total_exposure_index = 'DV Exposure Index',
  CPV = 'CPV'
}

export enum RevenueType {
  revCpm = 'rev_cpm',
  costPlus = 'cost_plus',
  other = 'other'
}

export enum BudgetType {
  MONETARY = 'monetary',
  IMPRESSION = 'impression',
  BOTH = 'both'
}

export enum FunnelOrigin {
  DSP = 'dsp',
  THIRD_PARTY = '3rd_party'
}

export enum FunnelType {
  COMPLETION = 'completion',
  VIEWABILITY = 'viewability',
  SEGMENT = 'segment',
  PIXEL = 'pixel',
  CLICK = 'click',
  AUDIBLE_VIEWABLE_COMPLETED = 'audible_viewable_completed'
}

export enum Period {
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
  Lifetime = 'Lifetime'
}
