<template>
  <v-navigation-drawer
  permanent
  fixed
  :mini-variant="mini"
  class="side-nav-overlay"
  :width="!mini ? '220px' : '80px'"
  >

    <div class='scibids-logo-div'>
        <v-img
            v-if="!mini"
            :src= "require('../../assets/scibids_logo.png')"
            :aspect-ratio= "8"
            class="scibids-logo bg-large-scibids-logo"
        />
        <v-img
          v-else
          :src= "require('../../assets/scibids_logo_only.png')"
          :aspect-ratio= "8"
          class="scibids-logo bg-mini-scibids-logo "
        />
    </div>

    <v-divider style="display: block !important"></v-divider>

    <v-list dense class="pt-0">

        <v-list-item v-if="mini" @click.stop="changeMini()">
          <v-list-item-action>
            <v-icon>chevron_right</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="!mini">
            <v-list-item-action >
                <v-btn icon @click.stop="changeMini()">
                <v-icon>chevron_left</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>

      <div class="logout-index-sidenav">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="$changeRoute(item.path)"
          :style="$route.path === item.path ? 'background-color: #F4F4F4;' : ''"
        >
          <v-tooltip  right>
            <template v-slot:activator="{ on }">
              <v-list-item-action>
                <v-icon v-on="on">{{ item.icon }}</v-icon>
              </v-list-item-action>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider style="display: block !important"></v-divider>
      </div>

      <!-- Side Menu Instructions Page SurcoucheV1 and SurcoucheV2 -->

      <div class="neutral-div" v-if="$route.name==='table' || $route.name==='surcoucheV2'">
        <v-list-item
            v-for="dsp in dspItems"
            :key="dsp.name"
            @click="
              $pageGuardTester(setRouteSurcouche($route.name, dsp.name))
              ? $changeRoute(setChangeRouteSurcouche ($route.name, dsp.name, $route.name === 'surcoucheV2' ?? $route.params.tab))
              : ''"
            :style="$route.params.dsp === dsp.name ? 'background-color: #F4F4F4;' : ''"
            :class="!$pageGuardTester(setRouteSurcouche($route.name, dsp.name)) ? 'disabledDsp' : ''"
            >
            <v-tooltip  right>
              <template v-slot:activator="{ on }">
                <v-list-item-avatar :tile="dsp.tile" v-on="on" :size="20"
                  :class="!$pageGuardTester(setRouteSurcouche($route.name, dsp.name)) ? 'disabledDsp' : ''"
                >
                    <img
                    :src="require('../../assets/' + dsp.name + '.png')"

                     alt="dsp photo"/>
                </v-list-item-avatar>
              </template>
              <span>{{dsp.displayedName}} {{!$pageGuardTester(setRouteSurcouche($route.name, dsp.name)) ? '(Available soon)' : ''}}</span>
            </v-tooltip>

            <v-list-item-title>{{dsp.displayedName}}</v-list-item-title>
        </v-list-item>
      </div>

      <!-- Side menu alertings -->

      <div class="neutral-div" v-if="alertingsRoute.indexOf($route.name) !== -1">
        <v-list-item
            v-for="page in alertingItems"
            :key="page.name"
            @click="$pageGuardTester(page.name) ? $changeRoute({ name: page.name }) : ''"
            :style="$route.name === page.name ? 'background-color: #F4F4F4;' : ''"
            :class="!$pageGuardTester(page.name) ? 'disabledDsp' : ''"
            >

            <v-tooltip  right>
              <template v-slot:activator="{ on }">
                <v-list-item-action>
                  <v-icon :style="page.iconColor !== undefined ? `color: ${page.iconColor}` : ''" v-on="on">{{ page.icon }}</v-icon>
                </v-list-item-action>
              </template>
              <span>{{ page.displayedName }}</span>
            </v-tooltip>

            <v-list-item-title>{{page.displayedName}}</v-list-item-title>
        </v-list-item>
      </div>

      <div class="neutral-div" v-if="keystoneRoute.indexOf($route.name) !== -1">
        <v-list-item
          v-for="page in keystoneItems"
          :key="page.name"
          @click="$pageGuardTester(page.name) ? $changeRoute({ name: page.name }) : ''"
          :style="$route.name === page.name ? 'background-color: #F4F4F4;' : ''"
          :class="!$pageGuardTester(page.name) ? 'disabledDsp' : ''"
        >

          <v-tooltip  right>
            <template v-slot:activator="{ on }">
              <v-list-item-action>
                <v-icon :style="page.iconColor !== undefined ? `color: ${page.iconColor}` : ''" v-on="on">{{ page.icon }}</v-icon>
              </v-list-item-action>
            </template>
            <span>{{ page.displayedName }}</span>
          </v-tooltip>

          <v-list-item-title>{{page.displayedName}}</v-list-item-title>
        </v-list-item>
      </div>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SideNav',
  data () {
    return {
      items: [
        { title: 'Home', icon: 'dashboard', path: '/' },
        { title: 'Logout', icon: 'power_settings_new', path: '/Logout' }
      ],
      alertingsRoute: ['alertingsIndex', 'alertingsMain'],
      keystoneRoute: ['keystoneOverview', 'keystoneTools', 'keystoneTool', 'keystoneV2', 'newFeatureRefsManager', 'billingEntities', 'proactiveSupportIO', 'userRightsViewer'],
      dspItems: [
        {
          name: this.$APPNEXUS,
          displayedName: 'AppNexus',
          tile: false
        },
        {
          name: this.$DBM,
          displayedName: 'DBM',
          tile: false
        },
        {
          name: this.$THETRADEDESK,
          displayedName: 'TheTradeDesk',
          tile: false
        },
        {
          name: this.$YOUTUBE,
          displayedName: 'Youtube',
          tile: true
        },
        {
          name: this.$BEESWAX,
          displayedName: 'Beeswax',
          tile: false
        },
        {
          name: this.$META,
          displayedName: 'Meta',
          tile: true
        },
        {
          name: this.$GOOGLEADS,
          displayedName: 'Google Ads',
          tile: true
        }
      ],
      alertingItems: [
        {
          name: 'alertingsMain',
          displayedName: 'List Alerts',
          icon: 'notification_important',
          iconColor: 'dodgerblue'
        },
        {
          name: 'alertingsIndex',
          displayedName: 'Index Alerts',
          icon: 'view_list',
          iconColor: 'cornflowerblue'
        }
      ],
      keystoneItems: [
        {
          name: 'keystoneV2',
          displayedName: 'List keystone members',
          icon: 'view_list',
          iconColor: 'cornflowerblue'
        },
        {
          name: 'keystoneTools',
          displayedName: 'List keystone tool',
          icon: 'build',
          iconColor: 'cornflowerblue'
        },
        {
          name: 'newFeatureRefsManager',
          displayedName: 'Manager new unreleased features',
          icon: 'lightbulb',
          iconColor: 'cornflowerblue'
        },
        {
          name: 'billingEntities',
          displayedName: 'List billing entities',
          icon: 'monetization_on',
          iconColor: 'cornflowerblue'
        },
        {
          name: 'proactiveSupportIO',
          displayedName: 'Proactive support rules',
          icon: 'add_to_photos',
          iconColor: 'cornflowerblue'
        },
        {
          name: 'userRightsViewer',
          displayedName: 'View user rights',
          icon: 'supervised_user_circle',
          iconColor: 'cornflowerblue'
        }
      ],
      right: null,
      fixed: true,
      mini: true
    }
  },
  methods: {
    changeMini () {
      this.mini = !this.mini
      this.$emit('nav-change')
    },
    setRouteSurcouche (routeName, dsp) {
      if (routeName === 'surcoucheV2') {
        return 'surcoucheV2/' + dsp
      } else {
        return dsp
      }
    },
    setChangeRouteSurcouche (routeName, dsp, tab) {
      if (routeName === 'surcoucheV2') {
        return { name: 'surcoucheV2', params: { dsp: dsp, tab: tab } }
      } else {
        return { name: 'table', params: { dsp: dsp, tab: '' } }
      }
    }
  },
  watch: {
  }
}
</script>
<style>
.scibids-logo {
    height: 100%;
}

.scibids-logo .v-image__image.v-image__image--cover {
    background-size: 46%;
    background-position: center center
}

.scibids-logo-div {
    height: 8em;
}

.disabledDsp {
  filter: grayscale(1.0);
  opacity: 0.5;
}

  .logout-index-sidenav {
    margin-bottom: 3em;
  }

  .bg-mini-scibids-logo {
    background: white;
  }

  .bg-large-scibids-logo {
    background: #554848;
  }
</style>
