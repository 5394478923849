import { ratioToPerc, roundNum } from '../utils/commonUtils'
import {
  createMainDataFuckAlerts,
  createMainDataUnderdelivery,
  getConcernedLevel,
  getDataUnderdeliveryOverdelivery,
  getLevel2SentenceIOForAlertDescription,
  getLevelSentenceIOForAlertDescription
} from './alertsCodeHelper'
import { $APPNEXUS, $DBM, $THETRADEDESK, $YOUTUBE } from './dspConfig'

// TODO split alert_type into different files
/**
 * contain information about error type
 * Data structure : Tree
 * Use the functions below (getInfoErrorType, getInfoError) for access data
 * @type {*[]}
 */
export const alertsCode = [
  {
    digit: '1',
    name: 'real time alerts',
    color: '#C34133',
    children: [
      {
        digit: '1',
        name: 'negative margin alerts',
        children: [
          {
            digit: '1',
            name: 'FUCK FUCK FUCK FUCK',
            component: 'FuckFuckFuckFuck',
            mainData: (data, settings = null, groupKey = null) => {
              return createMainDataFuckAlerts(data, settings, groupKey)
            },
            ultraDetailedData: null,
            infos: {
              definition: '',
              detailedInfos: '',
              action: ''
            }
          },
          {
            digit: '2',
            name: 'FUCK&sup2;',
            component: 'Fuck',
            mainData: (data, settings = null, groupKey = null) => {
              return createMainDataFuckAlerts(data, settings, groupKey)
            },
            ultraDetailedData: null
          },
          {
            digit: '3',
            name: 'HOURLY NEGATIVE MARGIN',
            component: 'AlertingsMargin',
            mainData: (data, settings = null, groupKey = null) => {
              return `Negative profit of <strong>${roundNum(data.real_profit)}</strong> USD during hour ${data.hour}`
            },
            ultraDetailedData: null
          }
        ]
      },
      {
        digit: '2',
        name: 'opti / datastore alerts',
        children: [
          {
            digit: '1',
            name: 'UNCOMPLETE IOs',
            component: 'UncompleteIos',
            mainData: (data, settings = null, groupKey = null) => {
              let len = data.line_items !== undefined ? data.line_items.split(',').length : ''
              let type = typeof data === 'object' ? Object.keys(data)[0] : ''
              return `There are <strong>${len}</strong> non optimized <strong>${type}</strong>`
            },
            ultraDetailedData: null
          },
          {
            digit: '2',
            name: 'COLLISION ON CAMPAIGNS',
            component: 'CollisionOnCampaigns',
            mainData: (data, settings = null, groupKey = null) => {
              return `CollisionOnCampaigns`
            },
            ultraDetailedData: null
          },
          {
            digit: '3',
            name: 'COLLISION ON OTTO',
            component: 'CollisionOnOtto',
            mainData: (data, settings = null, groupKey = null) => {
              return `CollisionOnOtto`
            },
            ultraDetailedData: null
          },
          {
            digit: '4',
            name: 'WRONG SYNTAX',
            component: 'WrongSyntax',
            mainData: (data, settings = null, groupKey = null) => {
              return `<strong>${data.errors[0].error_messages.join(' | ')}</strong>`
            },
            ultraDetailedData: 'WrongSyntaxDetailed',
            ultraDetailedHeader: [
              { label: 'Date of alerts' },
              { label: 'Lien datastore' },
              { label: 'Instruction id' },
              { label: 'Error message' }
            ],
            ultraDetailedItem: 'data.errors'
          }
        ]
      },
      {
        digit: '3',
        name: 'pacing real time alerts',
        children: [
          {
            digit: '1',
            name: 'INTRADAY PACING OFF TRACKS',
            component: 'IntradayPacingOffTracks',
            mainData: (data, settings = null, groupKey = null) => {
              const dsp = groupKey.dsp
              if (settings.type_alert === 'underdelivery') {
                if (
                  parseFloat(data.no_respect[0].CPM_billing) > parseFloat(settings.constraint_max_CPM) &&
                  data.no_respect[0].percentage_positive_imps === '100%' &&
                  data.max_freq_reached === 'true'
                ) {
                  return `Impossible to achieve the CPM constraint & deliver the daily budget for this IO/campaign`
                } else if (parseFloat(data.limiting_rate_max_A) === 100) {
                  const kpiBilling = parseFloat(data.no_respect[0].KPI_billing)
                  const kpiTargetValue = parseFloat(data.no_respect[0].KPI_target_value)
                  if (kpiBilling > kpiTargetValue) {
                    return `Impossible to achieve the KPI target & deliver the daily budget for this IO/campaign`
                  } else if (kpiBilling < kpiTargetValue) {
                    return `The max A applied for this IO is preventing the AI from delivering the daily budget for this IO/campaign`
                  }
                } else if (data.no_respect[0].lvl === 'IO') {
                  const spendUnder = data.no_respect[0].score_under
                  let ioLabel = ''
                  if ([$DBM, $YOUTUBE, $APPNEXUS].includes(dsp)) {
                    ioLabel = 'IO'
                  } else {
                    ioLabel = 'Campaign'
                  }
                  return `The ${ioLabel} will underpace today without intervention. Scibids AI estimates spend at
                        <strong>${spendUnder}</strong> by end of day.`
                } else if (data.no_respect[0].lvl === 'LI') {
                  const spendUnder = data.no_respect[0].score_under
                  return `Some line items will underpace today without intervention. Scibids AI estimates spend at
                            <strong>${spendUnder}</strong> by end of day.`
                }
                return `This campaign is currently underpacing by <strong>${data.max_offtracks}</strong> vs <strong>${settings.percent_expected}</strong> at ${settings.hour_check} at the <strong>IO level</strong>`
              } else if (settings.type_alert === 'overdelivery') {
                if (data.concerned_lvl === 'the IO') {
                  return `This campaign is currently overpacing for the day by <strong>${data.max_offtracks}</strong> vs <strong>${settings.percent_expected}</strong> at ${settings.hour_check} at the <strong>IO level</strong>`
                } else if (data.concerned_lvl === 'some line items') {
                  return `<strong>Some line items</strong> for this campaign are currently overpacing for the day by <strong>${data.max_offtracks}</strong> vs <strong>${settings.percent_expected}</strong> at ${settings.hour_check}`
                }
              }
              return `${settings.type_alert.ucwords()} up to <strong>${data.max_offtracks}</strong> vs <strong>${settings.percent_expected}</strong> at ${settings.hour_check} for <strong>${data.concerned_lvl}</strong>`
            },
            ultraDetailedData: 'IntradayPacingOffTracksDetailed',
            ultraDetailedHeader: [
              { label: 'Date of alerts' },
              { label: 'Hour' },
              { label: 'Lvl' },
              { label: 'Id' },
              { label: 'Delivery (%)' },
              { label: 'Delivery (current)' },
              { label: 'Delivery Obj' }
            ],
            ultraDetailedItem: 'data.no_respect'
          },
          {
            digit: '2',
            name: 'UNDERDELIVERY END OF BILLING',
            component: 'UnderdeliveryEndOfBilling',
            mainData: (data, settings = null, groupKey = null) => {
              const { forThePastXDays, concernedLvl, percent } = getDataUnderdeliveryOverdelivery(data)
              let level = getLevelSentenceIOForAlertDescription(concernedLvl)
              let daysLeft = 'NC'
              let lvlSimple = getConcernedLevel(concernedLvl)

              if (lvlSimple === 'li') {
                return `Some <strong>line items</strong> underdelivered by <strong>${percent}</strong>% yesterday`
              } else if (lvlSimple === 'io') {
                if (settings?.remaining_days_info?.io && typeof settings?.remaining_days_info?.io === 'object') {
                  daysLeft = Object.values(settings.remaining_days_info.io)[0]
                }
              }

              return `${level} <strong>${daysLeft}</strong> days left in its flight and underdelivered by <strong>${percent}</strong>% yesterday`
            },
            ultraDetailedData: 'UnderdeliveryEndOfBillingDetailed',
            ultraDetailedHeader: [
              { label: 'Date of alerts' },
              { label: 'Lvl' },
              { label: 'Id' },
              { label: 'Obj D-1' },
              { label: 'Spend D-1' },
              { label: 'Underdelivery D-1' },
              { label: 'Consecutive days of underdelivery' },
              { label: 'Weight in the IO' },
              { label: 'Underdelivery score' },
              { label: 'A_param_otto' },
              { label: 'B_param_otto' },
              { label: 'Delivery ok for Otto' },
              { label: 'Otto limited by constraint' }
            ],
            ultraDetailedItem: 'data.no_respect'
          },
          {
            digit: '3',
            name: 'PERF FIRST : NO DELIVERY',
            component: 'PerfFirstNoDelivery',
            mainData: (data, settings = null, groupKey = null) => {
              return `This IO spent less than 5% of the daily spend before activation`
            },
            ultraDetailedData: 'PerfFirstNoDeliveryDetailed',
            ultraDetailedHeader: [
              { label: 'Date of alerts' },
              { label: 'Lvl' },
              { label: 'Id' },
              { label: 'Spend day before activation' },
              { label: 'Spend day-1' }
            ],
            ultraDetailedItem: 'alert.no_respect'
          },
          {
            digit: '4',
            name: 'PERF FIRST : PERFORMANCE IS OFF TRACK',
            component: 'PerfPerformanceIsOffTrack',
            mainData: (data, settings = null, groupKey = null) => {
              let kpiYesterday = data.KPI_day_1 ? data.KPI_day_1 : 'NC'
              let kpiTarget = data.KPI_target ? data.KPI_target : 'NC'
              let kpiPeriod = data.KPI_period ? data.KPI_period : 'NC'

              if (data.period_evaluation_kpi === 'daily') {
                return `KPI is <strong>${kpiYesterday}</strong> vs <strong>${kpiTarget}</strong> threshold`
              }
              return `KPI is <strong>${kpiPeriod}</strong> vs <strong>${kpiTarget}</strong> threshold`
            }
          }
        ]
      },
      {
        digit: '4',
        name: 'alerting_otto_status',
        children: [
          {
            digit: '1',
            name: 'IO SHOULD NOT BE IN OTTO',
            component: 'IoShouldNotBeInOtto',
            mainData: (data, settings = null, groupKey = null) => {
              const nbOfCheck = data !== undefined && data !== null ? Object.keys(data).length : 'NC'
              return `This IO should not be in Otto <strong>${nbOfCheck}</strong> checks have failed`
            },
            ultraDetailedData: null
          }
        ]
      }
    ]
  },
  {
    digit: '2',
    name: 'daily alerts',
    color: '#FF8700',
    children: [
      {
        digit: '1',
        name: 'pacing daily alerts',
        children: [
          {
            digit: '1',
            name: 'OVERPACING',
            component: 'Overdelivery',
            mainData: (data, settings = null, groupKey = null) => {
              const { forThePastXDays, concernedLvl, percent } = getDataUnderdeliveryOverdelivery(data, true)
              let level = getLevelSentenceIOForAlertDescription(concernedLvl)
              let level2 = getLevel2SentenceIOForAlertDescription(concernedLvl)
              return `<strong>${level}</strong> over paced for the past <strong>${forThePastXDays}</strong> day(s). Yesterday <strong>${level2}</strong> over paced by <strong>${percent}%</strong>`
            },
            ultraDetailedData: 'OverdeliveryDetailed',
            ultraDetailedHeader: [
              { label: 'Date of alerts' },
              { label: 'Lvl' },
              { label: 'Id' },
              { label: 'Obj D-1' },
              { label: 'Last hour D-1' },
              { label: 'Overdelivery D-1' },
              { label: 'Consecutive days of overdelivery' },
              { label: 'Weight in the IO' },
              { label: 'Overdelivery score' },
              { label: 'A_param_otto' },
              { label: 'B_param_otto' },
              { label: 'Delivery ok for Otto' },
              { label: 'Otto limited by constraint' }
            ],
            ultraDetailedItem: 'data.no_respect'
          },
          {
            digit: '2',
            name: 'UNDERDELIVERY END OF BILLING',
            component: 'UnderdeliveryEndOfBilling',
            mainData: (data, settings = null, groupKey = null) => {
              return createMainDataUnderdelivery(data, settings)
            },
            ultraDetailedData: 'UnderdeliveryEndOfBillingDetailed',
            ultraDetailedHeader: [
              { label: 'Date of alerts' },
              { label: 'Lvl' },
              { label: 'Id' },
              { label: 'Obj D-1' },
              { label: 'Spend D-1' },
              { label: 'Underdelivery D-1' },
              { label: 'Consecutive days of underdelivery' },
              { label: 'Weight in the IO' },
              { label: 'Underdelivery score' }
            ],
            ultraDetailedItem: 'data.no_respect'
          },
          {
            digit: '3',
            name: 'UNDERDELIVERY',
            component: 'Underdelivery',
            mainData: (data, settings = null, groupKey = null) => {
              const { forThePastXDays, concernedLvl, percent } = getDataUnderdeliveryOverdelivery(data)
              let level = getLevelSentenceIOForAlertDescription(concernedLvl)
              let level2 = getLevel2SentenceIOForAlertDescription(concernedLvl)
              return `<strong>${level}</strong> underdelivered for the past <strong>${forThePastXDays}</strong> day(s). Yesterday <strong>${level2}</strong> underdelivered by <strong>${percent}</strong>`
            },
            ultraDetailedData: 'UnderdeliveryDetailed',
            ultraDetailedHeader: [
              { label: 'Date of alerts' },
              { label: 'Lvl' },
              { label: 'Id' },
              { label: 'Obj D-1' },
              { label: 'Spend D-1' },
              { label: 'Underdelivery D-1' },
              { label: 'Consecutive days of underdelivery' },
              { label: 'Weight in the IO' },
              { label: 'Underdelivery score' },
              { label: 'A_param_otto' },
              { label: 'B_param_otto' },
              { label: 'Delivery ok for Otto' },
              { label: 'Otto limited by constraint' }
            ],
            ultraDetailedItem: 'data.no_respect'
          },
          {
            digit: '4',
            name: 'PERF FIRST: DELIVERY DROP',
            component: 'PerfFirstDeliveryDrop',
            mainData: (data, settings = null, groupKey = null) => {
              let drop = 'NC'
              if (data.no_respect && data.no_respect.length && data.no_respect[0].drop) {
                drop = data.no_respect[0].drop
              }

              return `The daily spending for yesterday has dropped by <strong>${drop}%</strong> compared to to the day before`
            },
            ultraDetailedData: 'PerfFirstDeliveryDropDetailed',
            ultraDetailedHeader: [
              { label: 'Date of alerts' },
              { label: 'Lvl' },
              { label: 'Id' },
              { label: 'Spend D-2' },
              { label: 'Spend D-1' }
            ],
            ultraDetailedItem: 'data.no_respect'
          },
          {
            digit: '5',
            name: 'IO STUCK IN COMPUTING',
            component: 'IoStuckInComputing',
            mainData: (data, settings = null, groupKey = null) => {
              let message = 'NC'
              if (data && data.main_data) message = data.main_data
              return `${message}`
            },
            ultraDetailedData: null
          }
        ]
      },
      {
        digit: '2',
        name: 'non-respected constraints alerts',
        children: [
          {
            digit: '1',
            name: 'VIEW RATE TOO LOW VS ACCEPTED THRESHOLDS',
            component: 'ViewRateTooLowVsAcceptedThresholds',
            mainData: (data, settings = null, groupKey = null) => {
              return `View rate of <strong>${ratioToPerc(data.current_viz)} % </strong> vs <strong>${ratioToPerc(data.expected_viz)} %</strong> threshold`
            },
            ultraDetailedData: null
          },
          {
            digit: '2',
            name: 'CPM TOO LOW / HIGH VS ACCEPTED THRESHOLDS',
            component: 'CpmTooLowOrHight',
            mainData: (data, settings = null, groupKey = null) => {
              let toPrint = ''

              if (data.expected_min_CPM !== undefined) {
                toPrint = data.expected_min_CPM
              } else {
                toPrint = data.expected_max_CPM
              }
              return `CPM of <strong>${data.current_CPM}</strong> ${data.currency} vs <strong>${toPrint}</strong> ${data.currency} threshold`
            },
            ultraDetailedData: null
          },
          {
            digit: '3',
            name: 'MARGIN TOO LOW VS ACCEPTED THRESHOLDS',
            component: 'MarginTooLowVs',
            mainData: (data, settings = null, groupKey = null) => {
              return `Average Margin of <strong>${ratioToPerc(data.current_margin)} %</strong>  vs  <strong>${ratioToPerc(data.expected_margin)} %</strong> threshold`
            },
            ultraDetailedData: null
          },
          {
            digit: '4',
            name: ' LIMITING MAX A',
            component: 'LimitingMaxA',
            mainData: (data, settings = null, groupKey = null) => {
              if (!data.IO_is_perf_first) {
                return 'This IO has reached its max A. If the KPI is on track, remove the max A.'
              } else if (data.IO_is_perf_first) {
                return 'This IO has reached its max A, and may be at risk of not delivering its full budget. Remove or increase the max A.'
              }

              return `NC`
            },
            ultraDetailedData: null
          },
          {
            digit: '5',
            name: 'INCOHERENCE IN DATASTORE SETTINGS V2',
            component: 'IncoherenceInDatastoreSettingsV2',
            mainData: (data, settings = null, groupKey = null) => {
              let keys = Object.keys(data)
              if (keys.length === 1) {
                let value = keys[0]
                if (value === 'revenue_type') {
                  return '<strong>Revenue_Type</strong> is not matching DSP settings'
                } else if (value === 'min_viz') {
                  return '<strong>Measured Viewability</strong> is not available for some line items'
                }
              }
              // if more than 2 keys, we return the old format
              return `Check at <strong>${Object.keys(data).join(', ')}</strong> level(s) show incoherence in surcouche settings`
            },
            ultraDetailedData: null
          },
          {
            digit: '6',
            name: 'VALIDATED IO NOT PASSING CHECK PREMEP',
            component: 'ValidatedNotPassingCheckPremep',
            mainData: (data, settings = null, groupKey = null) => {
              return `This io does no longer pass the check_premep. This can severely decrease performance,
                please go to surcouche and click on the “Blocking” button to know the blocking parameters`
            },
            ultraDetailedData: null
          },
          {
            digit: '7',
            name: 'Validated IO with incoherent brief vs setup',
            component: 'ValidatedIOIncoherentCheckPremep',
            mainData: (data, settings = null, groupKey = null) => {
              return `This io does no longer have a coherent brief in regard to its setup.
              This can severely decrease performance, please go to surcouche look at the problematic parameters`
            },
            ultraDetailedData: null
          }
        ]
      },
      {
        digit: '3',
        name: 'alerting_otto_status',
        children: [
          {
            digit: '1',
            name: 'AI OPTIMIZATION DIFFERENT FROM MAIN PIXEL(S)',
            component: 'OptimizationMainPixels',
            mainData: (data, settings = null, groupKey = null) => {
              return `${data.main_data}`
            },
            ultraDetailedData: null
          },
          {
            digit: '2',
            name: 'OTTO NOT PRESENT',
            component: null,
            mainData: (data, settings = null, groupKey = null) => {
              return `${data.Message}`
            },
            ultraDetailedData: null
          },
          {
            digit: '3',
            name: 'TARGETING CONTAINS BOTH WEB AND APP',
            component: 'TargetingWebAndApp',
            mainData: (data, settings = null, groupKey = null) => {
              if (data.all_or_some_li === 'all') {
                return 'This IO is targeting both App & Web environment at the LI level, you should switch to custom bidding'
              } else {
                return 'Some of the LI(s) are targeting both App & Web environment, you should switch to custom bidding'
              }
            },
            ultraDetailedData: null
          },
          {
            digit: '4',
            name: 'PIXEL(S) NO LONGER DETECTED IN THE DSP',
            component: 'PixelNoLongerDetected',
            mainData: (data, settings = null, groupKey = null) => {
              return 'Some of the pixel(s) set-up in the surcouche in step 1 are no longer detected in the DSP.'
            },
            ultraDetailedData: null
          }

        ]
      },
      {
        digit: '4',
        name: 'alerting_otto_status',
        children: [
          {
            digit: '1',
            name: 'MARKUP MODULE ALERT',
            component: 'MarkupModuleAlert',
            mainData: (data, settings = null, groupKey = null) => {
              const dsp = groupKey.dsp
              let message = ''

              if (dsp === $THETRADEDESK) {
                message = `
                This IO should have its raising fee updated to <strong>${data['Possible raising_fee_scibids']} ${data['margin_fee_unit']}</strong>
                (fee type = <strong>${data['margin_fee_type']}</strong>) instead of <strong>${data['Current raising_fee_scibids']}</strong>
                (to attain an overall margin of <strong>${data['Possible min allowed margin']}</strong> coming from <strong>${data['Current min allowed margin']}</strong>).
                `
              } else {
                if (settings && settings.action === 'ratio') {
                  message = `This IO should have its ratio updated to increase profit.`
                } else if (data.rev_CPM) {
                  message = `This IO should have its Expected Margin Daily param updated from ${data['Current min allowed margin']} to ${data['Possible min allowed margin']}`
                } else {
                  message = `This IO should have its markup updated from ${data['Current markup cost']} to ${data['Possible markup cost']}`
                }
              }

              return message
            },
            ultraDetailedData: null
          },
          {
            digit: '2',
            name: 'MARKUP MODULE FORM',
            component: 'MarkupModuleForm',
            mainData: (data, settings = null, groupKey = null) => {
              return `This IO could have its margin raised from ${data['Current markup cost']} to ${data['Possible markup cost']}`
            },
            ultraDetailedData: null
          }
        ]
      },
      {
        digit: '5',
        name: 'DANGEROUS SETTING',
        children: [
          {
            digit: '1',
            name: 'SUB OPTIMAL CONSOLE SETTINGS',
            component: 'SubOptimalConsoleSettings',
            mainData: (data, settings = null, groupKey = null) => {
              return `This IO will underperform because it has a flight ahead or even pacing at the IO level. Change it to ASAP.`
            },
            ultraDetailedData: null
          },
          {
            digit: '2',
            name: 'SUB OPTIMAL CONSOLE SETTINGS',
            component: 'SubOptimalConsoleSettingsTrueview',
            mainData: (data, settings = null, groupKey = null) => {
              return data['Problematic Setup']
            },
            ultraDetailedData: null
          }
        ]
      }
    ]
  },
  {
    digit: '3',
    name: 'weekly alerts',
    color: '#0571ba',
    children: [
      {
        digit: '1',
        name: 'alerts setup (customer action)',
        children: [
          {
            digit: '1',
            name: 'HETEROGENEOUS BUSINESS MODELS',
            component: 'HeterogeneousBusinessModels',
            mainData: (data, settings = null, groupKey = null) => {
              return `HeterogeneousBusinessModels`
            },
            ultraDetailedData: null
          },
          {
            digit: '2',
            name: 'UNSUPPORTED BUSINESS MODELS',
            component: 'UnsupportedBusinessModels',
            mainData: (data, settings = null, groupKey = null) => {
              const mixedBusinessModels = data.mixed_business_models

              if (mixedBusinessModels) {
                return `This IO has a mix of business models and <strong>${settings['nb not supported line_items']}</strong>
                        LI out of <strong>${settings['nb active line_items']}</strong> have unsupported business model`
              }

              return `This IO has <strong>${settings['nb not supported line_items']}</strong>
                      LI out of <strong>${settings['nb active line_items']}</strong> have unsupported business models`
            },
            ultraDetailedData: 'UnsupportedBusinessModelDetailed',
            ultraDetailedHeader: [
              { label: 'Date of alerts' },
              { label: 'IO ID' },
              { label: 'LI ID' },
              { label: 'type_budget' },
              { label: 'revenue_type' },
              { label: 'revenue_value' },
              { label: 'Supported business models' }
            ],
            ultraDetailedItem: 'data.global_data'
          },
          {
            digit: '3',
            name: 'HETEROGENEOUS ATTRIBUTION WINDOW IN THE FUNNEL',
            component: 'HeterogeneousBusinessModels',
            mainData: (data, settings = null, groupKey = null) => {
              return `HeterogeneousBusinessModels`
            },
            ultraDetailedData: null
          },
          {
            digit: '4',
            name: 'UNDERDELIVERY MAX A / MAX CPM (V2)',
            component: 'UnderdeliveryMaxAMaxCpm',
            mainData: (data, settings = null, groupKey = null) => {
              return `UnderdeliveryMaxAMaxCpm`
            },
            ultraDetailedData: null
          }
        ]
      },
      {
        digit: '2',
        name: 'important reminders (AM action)',
        children: [
          {
            digit: '1',
            name: 'FARMING ALERT',
            component: 'FarmingAlert',
            mainData: (data, settings = null, groupKey = null) => {
              return `FarmingAlert`
            },
            ultraDetailedData: null
          },
          {
            digit: '2',
            name: 'OTTO SHOULD BE ACTIVATED',
            component: 'OttoShouldBeActivated',
            mainData: (data, settings = null, groupKey = null) => {
              return `OttoShouldBeActivated`
            },
            ultraDetailedData: null
          },
          {
            digit: '3',
            name: 'CHRONIC ALERT (V2)',
            component: 'ChronicAlert',
            mainData: (data, settings = null, groupKey = null) => {
              return `ChronicAlert`
            },
            ultraDetailedData: null
          }
        ]
      }
    ]
  },
  {
    digit: '4',
    name: 'brief_alert',
    color: '#00ba7c',
    children: [
      {
        digit: '1',
        name: 'brief',
        children: [
          {
            digit: '1',
            name: 'ALERT BRIEF',
            component: 'BaseBrief',
            mainData: (data, settings = null, groupKey = null) => {
              return `A new brief has been sent for this group_key`
            },
            ultraDetailedData: null
          },
          {
            digit: '2',
            name: 'INSTRUCTION IN DRAFT',
            component: 'InstructionInDraft',
            mainData: (data, settings = null, groupKey = null) => {
              return `Instruction(s) are in draft.`
            },
            ultraDetailedData: null
          },
          {
            digit: '3',
            name: 'INSTRUCTION TO VALIDATE',
            component: 'InstructionToValidate',
            mainData: (data, settings = null, groupKey = null) => {
              return `More than 3 days since the instructions have been set to validate`
            },
            ultraDetailedData: null
          }
        ]
      },
      {
        digit: '2',
        name: 'brief template',
        children: [
          {
            digit: '1',
            name: 'ALERT BRIEF AUTOPLUGS',
            component: 'BaseBrief',
            mainData: (data, settings = null, groupKey = null) => {
              return `A new brief from autoplugs has been sent for this group_key`
            },
            ultraDetailedData: null
          },
          {
            digit: '2',
            name: 'INSTRUCTION IN DRAFT AUTOPLUGS',
            component: 'InstructionInDraft',
            mainData: (data, settings = null, groupKey = null) => {
              return `Instruction(s) from a autoplug brief are in draft.`
            },
            ultraDetailedData: null
          },
          {
            digit: '3',
            name: 'INSTRUCTION TO VALIDATE AUTOPLUGS',
            component: 'InstructionToValidate',
            mainData: (data, settings = null, groupKey = null) => {
              return `More than 3 days since the instructions from a autoplug brief have been set to validate`
            },
            ultraDetailedData: null
          },
          {
            digit: '4',
            name: 'BRIEF PRESENT BUT NOT ACTIVATED',
            component: 'TemplateBriefNotActivatedOrValidated',
            mainData: (data, settings = null, groupKey = null) => {
              let ruleName = data.autoplug_name ? data.autoplug_name : 'NC'
              let ruleId = data.autoplug_id ? data.autoplug_id : 'NC'
              let companyName = data.company_name ? data.company_name : 'NC'
              return `This IO should be plugged due to autoplug rule <strong>${ruleName}</strong> -
              <strong>${ruleId}</strong> from company <strong>${companyName}</strong>, but the matching brief is not active`
            },
            ultraDetailedData: null
          },
          {
            digit: '5',
            name: 'BRIEF PRESENT BUT NOT VALIDATED',
            component: 'TemplateBriefNotActivatedOrValidated',
            mainData: (data, settings = null, groupKey = null) => {
              let ruleName = data.autoplug_name ? data.autoplug_name : 'NC'
              let ruleId = data.autoplug_id ? data.autoplug_id : 'NC'
              let companyName = data.company_name ? data.company_name : 'NC'
              return `This IO should be plugged due to autoplug rule <strong>${ruleName}</strong> - <strong>${ruleId}</strong>
              from company <strong>${companyName}</strong>, but the matching brief is not validated`
            },
            ultraDetailedData: null
          },
          {
            digit: '6',
            name: 'IO HAS BLOCKING EDITABLE PROPERTIES',
            component: 'IoHasBlockingEditableProperties',
            mainData: (data, settings = null, groupKey = null) => {
              let ruleName = data.autoplug_name ? data.autoplug_name : 'NC'
              let ruleId = data.autoplug_id ? data.autoplug_id : 'NC'
              let companyName = data.company_name ? data.company_name : 'NC'
              return `
                This IO should be plugged due to autoplug rule ${ruleName} - ${ruleId} from company ${companyName},
                but the it has some blocking editable properties. Please run a check premep before optimizing it.
              `
            },
            ultraDetailedData: null
          }
        ]
      },
      {
        digit: '3',
        name: 'bulk add brief',
        children: [
          {
            digit: '1',
            name: 'ALERT BULK ADD BRIEF',
            component: 'BaseBrief',
            mainData: (data, settings = null, groupKey = null) => {
              return `A new bulk add brief has been sent for this group_key`
            },
            ultraDetailedData: null
          }
        ]
      }
    ]
  },
  {
    digit: '5',
    name: 'baseline alert',
    color: '#00dcff',
    children: [
      {
        digit: '1',
        name: 'ABTest',
        children: [
          {
            digit: '1',
            name: 'ABTestEnded',
            component: 'ABTestEnded',
            mainData: (data, settings = null, groupKey = null) => {
              return `Your A/B Test ${data.baseline_id} should be over by now. <br>
                        <strong>Did the algo beat all the other competitors?</strong>`
            },
            ultraDetailedData: null
          }
        ]
      }
    ]
  }
]

export class ErrorTypeDontExist extends Error {
  constructor (...args) {
    super(...args)
    Error.captureStackTrace(this, ErrorTypeDontExist)
    this.message = `The error type ${args[0].errorType} doesn't exist.`
    this.name = 'ErrorTypeDontExist'
  }
}

/**
 * return the name for a errorType
 * @param errorType : {String} a errorType like '1.1.1'
 * @returns {string}
 */
export function getInfoErrorType (errorType) {
  const splitted = splitErrorType(errorType)
  let code = {}
  try {
    code = alertsCode[splitted[0]].children[splitted[1]].children[splitted[2]]
  } catch (TypeError) {
    throw new ErrorTypeDontExist({ errorType: errorType })
  }

  try {
    // eslint-disable-next-line no-unused-expressions
    code.name
  } catch (ReferenceError) {
    throw new ErrorTypeDontExist({ errorType: errorType })
  }

  if (code.name === undefined) {
    throw new ErrorTypeDontExist({ errorType: errorType })
  }

  return code.name
}

/**
 * return a object with information about the errorType
 * @param errorType : {String}
 * @returns { AlertConfigInfo }
 */
export function getInfoError (errorType) {
  const splitted = splitErrorType(errorType)

  let lenErrorType = splitted.length
  let currentCode = alertsCode[splitted[0]]

  if (currentCode === undefined || currentCode.name === undefined) {
    throw new ErrorTypeDontExist({ errorType: errorType })
  }

  let info = {
    searched: '',
    category: '',
    color: '',
    subcategory: '',
    name: '',
    mainData: () => {},
    component: '',
    ultraDetailedData: null,
    ultraDetailedHeader: null,
    ultraDetailedItem: null
  }

  info.searched = errorType
  info.category = currentCode.name
  info.color = currentCode.color

  for (let i = 1; i < lenErrorType; i++) {
    currentCode = currentCode.children[splitted[i]]

    if (currentCode === undefined || currentCode.name === undefined) {
      throw new ErrorTypeDontExist({ errorType: errorType })
    }

    if (i === 1) {
      info.subcategory = currentCode.name
    } else {
      info.name = currentCode.name
      info.mainData = currentCode.mainData
      info.component = currentCode.component
      info.ultraDetailedData = currentCode.ultraDetailedData !== undefined ? currentCode.ultraDetailedData : null
      info.ultraDetailedHeader = currentCode.ultraDetailedHeader !== undefined ? currentCode.ultraDetailedHeader : null
      info.ultraDetailedItem = currentCode.ultraDetailedItem !== undefined ? currentCode.ultraDetailedItem : null
    }
  }

  return info
}

function splitErrorType (errorType) {
  return errorType.split('.').map((item) => {
    return '' + (Number(item) - 1)
  })
}
