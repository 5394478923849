import DspConfig from './DspConfigModel/DspConfig'
import { toMergeCommonMarkupModule, toMergeCommonCustomProdMgmt } from './commonDspConfig'
import { KpiValue } from '../../types/brief_enum'

export default new DspConfig({
  headers: [
    { text: 'info', value: 'group_name', sortable: false, onlyHeader: true },
    { text: 'Group Name', value: 'group_name', class: 'td-data-table-left' },
    { text: 'Member / Advertiser', value: 'account_id', sortable: false, class: 'td-data-table-center' },
    { text: 'IO/LI/CA', value: '', sortable: false, class: 'td-data-table-center' },
    { text: 'Objectives', value: '', sortable: false, class: 'td-data-table-left' },
    { text: 'Constraint_IO', value: '', sortable: false, class: 'td-data-table-left' }
  ],
  client_value: 'business_manager_id',
  ioField: 'campaign_id',
  newIoField: 'campaign_id',
  ioFieldLabel: 'Campaign Id',
  ioFieldAlert: 'campaign_id',
  adLabel: 'Account id',
  adField: 'advertiser_id',
  matchingFields: ['matching_adsets'],
  specialFields: ['constraints_campaign'],
  kpiToOptimizeOptions: [
    KpiValue.CPM,
    KpiValue.CPMr,
    KpiValue.ViewTR
  ],
  constraintsFormConfig: [
  ],
  overwritingFields: {
    normal: [
      { label: 'Overwrite Campaign Budget', value: 'overwrite_campaign_budget' }
    ],
    advanced: [
    ]
  },
  toMerge: [
    { prop: ['business_manager_id'] },
    { prop: ['advertiser_id'] },
    { prop: ['true_conversion_funnel_pixel_ids'] },
    { prop: ['conversion_funnel_pixel_ids'] },
    { prop: ['true_KPI_to_optimize'] },
    { prop: ['KPI_to_optimize'] },
    { prop: ['obj_watcher', 'true_KPI_to_optimize', 'value'] },
    { prop: ['obj_watcher', 'true_KPI_to_optimize', 'KPI'] },
    { prop: ['obj_watcher', 'KPI_to_optimize', 'value'] },
    { prop: ['obj_watcher', 'KPI_to_optimize', 'KPI'] },
    { prop: ['obj_watcher', 'CPA', 'value'] },
    { prop: ['objective', 'true_PV_window'] },
    { prop: ['objective', 'true_PC_window'] },
    { prop: ['objective', 'PV_window'] },
    { prop: ['objective', 'PC_window'] },
    { prop: ['status'] },
    { prop: ['max_frequency'] },
    { prop: ['overwrite_frequency'] },
    { prop: ['true_conv_measurement_tool'] },
    { prop: ['conv_measurement_tool'] },
    { prop: ['revenue_type'] },
    ...toMergeCommonMarkupModule,
    ...toMergeCommonCustomProdMgmt,
    { prop: ['opti_auto'] },
    { prop: ['strat_lvl'] },
    { prop: ['retry'] },
    { prop: ['fast_retry'] },
    { prop: ['true_pivot_variable'] },
    { prop: ['overwrite_campaign_budget'] },
    { prop: ['use_opti_ratio_surcouche'] }
  ]
})
