import appnexusConfig from './dspConfigPerDsp/appnexusConfig'
import beeswaxConfig from './dspConfigPerDsp/beeswaxConfig'
import dbmConfig from './dspConfigPerDsp/dbmConfig'
import facebookConfig from './dspConfigPerDsp/facebookConfig'
import mediamathConfig from './dspConfigPerDsp/mediamathConfig'
import thetradedeskConfig from './dspConfigPerDsp/thetradedeskConfig'
import youtubeConfig from './dspConfigPerDsp/youtubeConfig'
import kayzenConfig from './dspConfigPerDsp/kayzenConfig'
import { IDspConfig } from './dspConfigPerDsp/dspConfigPerDspTypes'
import { InstructionDsp, OptimizeKeys } from 'types/instruction_type'
import metaConfig from './dspConfigPerDsp/metaConfig'
import googleadsConfig from './dspConfigPerDsp/googleadsConfig'

/**
 * contain the config for each dsp
 *
 * BUSINESS LOGIC toMerge : insertion order / campaign fields to merge with instruction (IOForm)
 *
 *
 * BUSINESS LOGIC commonField : a list of fields to merge with all the dsp
 */

export const $APPNEXUS: InstructionDsp = 'appnexus'
export const $MEDIAMATH: InstructionDsp = 'mediamath'
export const $DBM: InstructionDsp = 'dbm'
export const $THETRADEDESK: InstructionDsp = 'thetradedesk'
export const $YOUTUBE: InstructionDsp = 'youtube'
export const $BEESWAX: InstructionDsp = 'beeswax'
export const $FACEBOOK: InstructionDsp = 'facebook'
export const $KAYZEN: InstructionDsp = 'kayzen'
export const $META: InstructionDsp = 'meta'
export const $GOOGLEADS: InstructionDsp = 'googleads'

export const $OPTIMIZE_CAMPAIGNS: OptimizeKeys = 'optimize_campaigns'
export const $OPTIMIZE_LINEITEMS: OptimizeKeys = 'optimize_lineitems'
export const $OPTIMIZE_ADGROUPS: OptimizeKeys = 'optimize_adgroups'
export const $OPTIMIZE_STRATEGIES: OptimizeKeys = 'optimize_strategies'
export const $OPTIMIZE_ADSETS: OptimizeKeys = 'optimize_adsets'

export const instructionsFieldEnabledPerDsp: Record<InstructionDsp, Array<string>> = {
  [$THETRADEDESK]: ['optiRatio', 'kpiCpa', 'maxCpm', 'offset'],
  [$DBM]: ['kpiCpa', 'maxCpm', 'offset', 'minViz', 'optiRatio'],
  [$MEDIAMATH]: ['kpiCpa', 'maxCpm', 'offset', 'optiRatio'],
  [$APPNEXUS]: ['kpiCpa', 'maxCpm', 'cRate', 'offset', 'minViz', 'optiRatio'],
  [$YOUTUBE]: ['kpiCpa', 'optiRatio'],
  [$BEESWAX]: ['kpiCpa', 'maxCpm', 'offset', 'optiRatio'],
  [$FACEBOOK]: ['max_budget', 'min_budget', 'optiRatio'],
  [$KAYZEN]: ['kpiCpa', 'maxCpm', 'offset', 'optiRatio'],
  [$META]: ['kpiCpa', 'optiRatio'],
  [$GOOGLEADS]: ['kpiCpa', 'optiRatio']
}

export type Headers = Array<{
  text: string,
  value: string,
  class: string,
  sortable?: boolean,
  onlyHeader?: boolean
}>

export type IOHeaderType = {
  headers: Headers
  headersV2: Headers
}

export type DspConfigBaseType = { [key in InstructionDsp]: IDspConfig }
export type DspConfigLineIOType = { IOHeader: IOHeaderType }
export type DspConfigType = DspConfigBaseType & DspConfigLineIOType

export const dspConfig: DspConfigType = {
  [$APPNEXUS]: appnexusConfig,
  [$MEDIAMATH]: mediamathConfig,
  [$DBM]: dbmConfig,
  [$THETRADEDESK]: thetradedeskConfig,
  [$BEESWAX]: beeswaxConfig,
  [$YOUTUBE]: youtubeConfig,
  [$FACEBOOK]: facebookConfig,
  [$KAYZEN]: kayzenConfig,
  [$META]: metaConfig,
  [$GOOGLEADS]: googleadsConfig,
  /**
   * special for LineIO
   */
  IOHeader: {
    headers: [
      { text: 'retry', value: '', class: 'info-header-table', sortable: false, onlyHeader: true },
      { text: 'info', value: '', class: 'info-header-table', sortable: false, onlyHeader: true },
      { text: 'Localization', value: '', class: 'localization-header-table', sortable: false, onlyHeader: true },
      { text: 'KPI to optimize', value: '', class: 'kpi-header-table', sortable: false },
      { text: 'Constraints to respect', value: '', class: 'constraints-header-table', sortable: false },
      { text: 'Algo strategy', value: '', class: 'algo-header-table', sortable: false },
      { text: 'DSP Overwriting', value: '', class: 'dsp-header-table', sortable: false }
    ],
    headersV2: [
      { text: '', value: 'checkbox', class: 'localization-header-table', sortable: false, onlyHeader: true },
      { text: '', value: 'buttons', class: 'info-header-table', sortable: false, onlyHeader: true },
      { text: '', value: 'names', class: 'info-header-table', sortable: false, onlyHeader: true },
      { text: 'Health', value: 'health', class: 'algo-header-table', sortable: false },
      { text: 'Alerts', value: 'alerts', class: 'constraints-header-table', sortable: false }
    ]
  }
}
