import { ApiCaller } from './apiCaller'
import _, { replace } from 'lodash'

/**
 * Class for easily call the Scibids Internal Api
 * Add the endpoint to the config, and se example for create the call
 * For get call, once the endpoint is setted to the config, just use the 'get' method :
 * ex :
 * # in config
 * apiConfig = {
 *   server: process.env.VUE_APP_API_HOST,
 *   calls: {
 *     alerts: {
 *       get: '/alerts'
 *     }
 *   }
 *  }
 * # in another js file
 * let api = new ScibidsApicaller(options, jwt)
 * let alerts = await api.get('alerts')
 *
 * Each call is cancellable with the method cancelCall
 * @author Quentin Gary
 * @copyright Scibids
 */
class ScibidsApiCaller extends ApiCaller {
  // OVERLAY (INSTRUCTION / SURCOUCHE )
  bucketsInstructions (data) {
    return this.put('bucketsInstructions', data)
  }

  postNewInstruction (data) {
    return this.post('new_instruction', data)
  }

  /**
   * @param data
   * @param cancel
   * @return {Promise<{data: Instruction[]}> | Promise<{data: CollectionInstruction}>}
   */
  getInstructions (data, cancel = true) {
    return this.get('instructions', data, null, cancel)
  }

  getInstructionsV2 (data, cancel) {
    return this.get('surcoucheV2Instructions', data, null, cancel)
  }

  getInstruction (data, instructionId) {
    return this.get('instruction', data, instructionId)
  }

  putInstruction (data, elementId) {
    return this.put('instruction', data, elementId)
  }

  deleteInstruction (data, elementId) {
    return this.delete('instruction', data, elementId)
  }

  /**
   * @param dsp {InstructionDsp}
   * @param instructionList {Instruction[]}
   * @param acknowledgments {number[]}
   * @param toDeleteId {number[]}
   * @param baseline {Baseline}
   * @returns {Promise<_.LoDashFp.T>}
   */
  batchInstruction (dsp, instructionList, acknowledgments = [], toDeleteId = [], baseline = null) {
    let data = {
      dsp: dsp,
      data: instructionList,
      acknowledgments: {
        acknowledgment_ids: acknowledgments.join(','),
        is_ok: true
      }
    }

    if (baseline) {
      data.baseline = baseline
    }

    if (toDeleteId && toDeleteId.length) {
      data.to_delete = {
        instruction_ids: toDeleteId
      }
    }
    return this.postRaw('instructions', data)
  }

  // ALERTINGS
  /**
   * Call the alertsIndex endPoint of the api who return a json
   * with the content of the spreadsheet who contain infos about the different error type of alert
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAlertsIndex () {
    return this.get('alertsIndex')
  }

  /**
   * Call the alertsSummary endPoint of the api and return a Promise with the data or the error
   * @returns {Promise<AxiosResponse<T>>}
   */
  getSummary () {
    return this.get('alertsSummary')
  }

  /**
   * get from the api all the non expired alerts
   * @param groupKeyId {Number}
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAlerts (groupKeyId) {
    const data = {
      group_key_id: groupKeyId
    }

    return this.get('alerts', data)
  }

  /**
   * get from the api all the non expired alerts
   * @param mailList{string[]}
   * @param limit {Number}
   * @param offset {Number}
   * @param search {string}
   * @param hideDoneAndFalsePositive {Boolean}
   * @param attributed {Boolean}
   * @param errorType {string | string[]}
   * @param debuggerList {string[]}
   * @param lastDate {Date}
   * @param baselineType {BaselineType[]}
   * @param stratLvl {StratLvl[]}
   * @param errorTypeNot {string | string[]}
   * @param onlyErrorTypes {boolean | null}
   * @param onlyErrorTypesNot {boolean | null}
   * @param optiAuto {boolean | null}
   * @param insertionOrderIds {string | string[]}
   * @returns {Promise<AxiosResponse<T>>}
   */
  getGroupKeys (mailList = null,
    limit = 20,
    offset = 0,
    search = null,
    hideDoneAndFalsePositive = true,
    attributed = null,
    errorType = null,
    debuggerList = null,
    lastDate = null,
    baselineType = null,
    stratLvl = null,
    errorTypeNot = null,
    onlyErrorTypes = null,
    onlyErrorTypesNot = null,
    optiAuto = null,
    insertionOrderIds = null) {
    let data = {
      limit: limit,
      offset: offset,
      hide_done_and_false_positive: hideDoneAndFalsePositive
    }
    if (mailList && Array.isArray(mailList) && mailList.length) {
      data.col_mails = mailList.join(',')
    }

    if (debuggerList && Array.isArray(debuggerList) && debuggerList.length) {
      data.debugger = debuggerList.join(',')
    }

    if (search) {
      data.search = search
    }

    if (!_.isNil(attributed)) {
      data.attributed = attributed
    }

    if (errorType) {
      if (Array.isArray(errorType)) {
        data.error_types = errorType.join(',')
      } else {
        data.error_types = errorType
      }
    }
    if (lastDate) {
      data.insertion_date = new Date(lastDate).toISOString()
    }
    if (baselineType) {
      data.baseline_type = baselineType
    }
    if (!_.isNil(stratLvl)) {
      data.strat_lvl = stratLvl.join(',')
    }
    if (errorTypeNot) {
      if (Array.isArray(errorTypeNot)) {
        data['error_types.not'] = errorTypeNot.join(',')
      } else {
        data['error_types.not'] = errorTypeNot
      }
    }

    if (onlyErrorTypes) {
      data.only_error_types = onlyErrorTypes
    }

    if (onlyErrorTypesNot) {
      data.only_error_types_not = onlyErrorTypesNot
    }

    if (optiAuto !== null) {
      data.opti_auto = optiAuto
    }
    if (insertionOrderIds) {
      if (Array.isArray(insertionOrderIds)) {
        data.insertion_order_ids = insertionOrderIds.join(',')
      } else {
        data.insertion_order_ids = insertionOrderIds
      }
    }

    return this.get('groupKeys', data, null, false)
  }

  getGroupKeysWithInsertionOrderId (insertionOrderIds) {
    return this.getGroupKeys(null, 100, 0, null, true,
      null, null, null, null, null, null, null, null,
      null, null, insertionOrderIds
    )
  }

  updateStatusGroupKey (groupKeyIds, status, to = null, content = null, removed = false) {
    const data = {
      id_list: groupKeyIds.join(','),
      message_type: status,
      message_to: to,
      content: content,
      removed: removed
    }
    return this.put('groupKeys', data)
  }

  /**
   * get the alert who have the groupKey givin in param
   * @param groupKeyId {object} a groupKey object
   * @param expired
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAlertsPerGroupKey (groupKeyId, expired = false) {
    let data = {
      group_key_id: groupKeyId,
      expired: expired
    }
    return this.get('alerts', data, null, false)
  }

  cancelAlertsBrief (groupKeyId, overviewId, errorType, reason, dsp) {
    let data = {
      group_key_id: groupKeyId,
      overview_id: overviewId,
      error_type: errorType,
      reason: reason,
      dsp: dsp
    }
    return this.post('cancelAlertBrief', data)
  }

  cancelAlertsBulkAddBrief (groupKeyId, bulkAddId, errorType, reason, dsp) {
    let data = {
      group_key_id: groupKeyId,
      bulk_add_id: bulkAddId,
      error_type: errorType,
      reason: reason,
      dsp: dsp
    }
    return this.post('cancelAlertBulkAddBrief', data)
  }

  snoozeAlert (groupKeyId, ioId, errorType, dsp, hours) {
    let data = {
      group_key_id: groupKeyId,
      io_id: ioId,
      error_type: errorType,
      dsp: dsp,
      hours: hours
    }

    return this.post('snoozeAlert', data)
  }

  // USERS
  getUsers () {
    return this.get('users')
  }

  getUser (userId) {
    return this.get('user', null, userId)
  }

  postUser (dvMail, isDebugger, isAm, isSale, isSettupper, isAdminAndFinance) {
    const data = {
      dvMail: dvMail,
      isAm: isAm,
      isDebugger: isDebugger,
      isSale: isSale,
      isSettupper: isSettupper,
      isAdminAndFinance: isAdminAndFinance
    }
    return this.post('users', data)
  }

  putUser (userId, isDebugger, isAm, isSale, isSettupper, isAdminAndFinance, dvMail = null) {
    const data = {
      isAm: isAm,
      isDebugger: isDebugger,
      isSale: isSale,
      isSettupper: isSettupper,
      isAdminAndFinance: isAdminAndFinance,
      dvMail: dvMail
    }
    return this.put('user', data, userId)
  }

  deleteUser (userId) {
    return this.delete('user', null, userId)
  }

  // INSTRUCTIONS STRATEGY CALLER

  __getInstructionStrat (data, status, recency, insertionDay, execMode, lineItemId = null) {
    if (status !== null) {
      data.status = Array.isArray(status) ? status.join(',') : status
    }

    if (recency !== null) {
      data.recency = recency
    }

    if (insertionDay !== null) {
      data.insertion_day = insertionDay
    }

    if (execMode !== null) {
      data.execution_mode = Array.isArray(execMode) ? execMode.join(',') : execMode
    }

    if (lineItemId) {
      data.line_item_id = lineItemId
    }

    return this.get('instructionsStrat', data, null, false)
  }

  getInstructionsStrat (instructionId, status, dsp, recency = null, insertionDay = null, limit = 100, offset = 0) {
    let data = {
      instruction_id: instructionId,
      dsp: dsp,
      limit: limit,
      offset: offset
    }

    return this.__getInstructionStrat(data, status, recency, insertionDay, null)
  }

  getInstructionsStratWithInsertionOrderid (
    insertionOrderId,
    status, dsp, recency = null,
    insertionDay = null,
    limit = 100,
    offset = 0,
    instructionId = null,
    executionMode = null,
    insertionDayGte = null,
    insertionDayLte = null,
    lineItemId = null
  ) {
    let data = {
      insertion_order_id: insertionOrderId,
      dsp: dsp,
      limit: limit,
      offset: offset
    }

    if (instructionId !== null) {
      data.instruction_id = instructionId
    }

    if (insertionDayGte !== null) {
      data['insertion_day.gte'] = insertionDayGte
    }

    if (insertionDayLte !== null) {
      data['insertion_day.lte'] = insertionDayLte
    }

    return this.__getInstructionStrat(data, status, recency, insertionDay, executionMode, lineItemId)
  }

  getInstructionsStratBucketIds (ids, dsp, cancel = true) {
    const data = {
      strats_id: ids.join(','),
      dsp: dsp
    }
    return this.get('instructionsStrat', data, null, cancel)
  }

  getModelStrat (modelId, dsp) {
    let data = {
      dsp: dsp
    }
    return this.get('modelStrat', data, modelId)
  }

  // Create client entity

  getBillingAccount (billingAccountId) {
    return this.get('billingAccount', null, billingAccountId)
  }

  getBillingAccountFromEntity (entityType, entityId) {
    return this.get('billingAccount', null, null, true, null, { 'entity_type': entityType, 'entity_id': entityId })
  }

  getReverseBillingAccountEntities (entityType, entityId) {
    return this.get('reverseBillingAccountEntities', null, null, true, null, { 'entity_type': entityType, 'entity_id': entityId })
  }

  getMetaHoldings (data = null) {
    return this.get('metaHoldings', data)
  }

  getMetaHolding (metaHoldingId) {
    return this.get('metaHolding', null, metaHoldingId)
  }

  getHoldings (data = null) {
    return this.get('holdings', data)
  }

  getHolding (holdingId) {
    return this.get('holding', null, holdingId)
  }

  getCompanies (data = null, cancelTokenSource = null) {
    return this.get('companies', data, null, true, null, null, cancelTokenSource)
  }

  getCompany (companyId) {
    return this.get('company', null, companyId)
  }

  getSieges (data = null, cancelTokenSource = null) {
    return this.get('sieges', data, null, true, null, null, cancelTokenSource)
  }

  getMembers (data = null) {
    return this.get('members', data)
  }

  getBillingEntities (data = null) {
    return this.get('billingEntities', data)
  }

  postMembers (name, dsp, externalId, isSet = true) {
    const data = {
      name: name,
      dsp: dsp,
      external_id: externalId,
      is_set: isSet
    }
    return this.post('members', data)
  }

  /**
   * @param data {{name: string}}
   * @return {Promise<T>}
   */
  postMetaHoldingKeystoneV2 (data) {
    return this.post('metaHoldings', data)
  }

  /**
   * @param data {{name: string}}
   * @param metaHoldingId {number}
   * @return {Promise<T>}
   */
  putMetaHolding (data, metaHoldingId) {
    return this.put('metaHolding', data, metaHoldingId)
  }

  /**
   * @param data {{name: string, has_fit_score: boolean}}
   * @return {Promise<T>}
   */
  postHoldingKeystoneV2 (data) {
    return this.post('holdings', data)
  }

  /**
   * @param data {{name: string, has_fit_score: boolean, has_insight: boolean}}
   * @param holdingId {number}
   * @return {Promise<T>}
   */
  putHolding (data, holdingId) {
    return this.put('holding', data, holdingId)
  }

  /**
   * @param data {{name: string, country_id: number, holding_id: number}}
   * @return {Promise<T>}
   */
  postCompanyKeystoneV2 (data) {
    return this.post('companies', data)
  }

  /**
   * @param data {{name: string, country_id: number, holding_id: number}}
   * @param companyId {number}
   * @return {Promise<T>}
   */
  putCompany (data, companyId) {
    return this.put('company', data, companyId)
  }

  postSiege (name, companyId) {
    const data = {
      name: name,
      company_id: companyId
    }
    return this.post('sieges', data)
  }

  /**
   * @param ioId
   * @param advertiserId
   * @param clientId
   * @return {Promise<{data: OverviewApiArgs[]}>}
   */
  getOverviewGroupKey (ioId, advertiserId, clientId, dsp = null) {
    const data = {
      external_id: ioId,
      advertiser_external_id: advertiserId,
      member_external_id: clientId
    }
    if (dsp != null) {
      data.sub_dsp = dsp
    }
    return this.get('overviews', data)
  }

  /**
   * @param params? object
   * @param cancel? boolean
   * @return {Promise<{data: OverviewApiArgs[]}>}
   */
  getOverviewsWithSearch (params, cancel = false) {
    let data = {
      search: 'search' in params ? params.search : null,
      id_list: 'idList' in params ? params.idList.join(',') : null,
      company_id: 'companyId' in params ? params.companyId : null,
      advertiser_id_list: 'advertiserIdList' in params ? params.advertiserIdList.join(',') : null,
      siege_id_list: 'siegeIdList' in params ? params.siegeIdList.join(',') : null,
      bulk_add_id: 'bulkAddId' in params ? params.bulkAddId : null,
      only_ids: 'onlyIds' in params ? params.onlyIds : null,
      sub_dsp: 'subDsp' in params ? params.subDsp : null,
      external_id: 'externalId' in params ? params.externalId : null,
      external_id_list: 'externalIdList' in params ? params.externalIdList.join(',') : null,
      limit: 'limit' in params ? params.limit : 500
    }
    // Removing key if data is null
    Object.keys(data).forEach(key => { if (data[key] == null) { delete data[key] } })
    return this.get('overviews', data, null, cancel)
  }

  /**
   * keystone
   */

  getDspConfigs (dsp, data = null) {
    return this.get('dspConfigs', data, dsp)
  }

  // REMOTE JOB

  getRemoteJobs (data = null) {
    return this.get('remoteJobs', data)
  }

  postRemoteJobs (data) {
    return this.postRaw('remoteJobs', data)
  }

  // SURCOUCHE CONFIG

  getSurcoucheConfig () {
    return this.get('surcoucheConfig', null, null, false)
  }

  // USER ROLE

  getUserRoles (userId) {
    return this.get('userRoles', null, null, true, userId)
  }

  putUserRoles (userIds, roles) {
    const data = {
      roles: roles
    }
    return this.put('userRoles', data, null, userIds.join(','))
  }

  deleteUserRoles (userIds, roles) {
    const data = {
      roles: roles
    }
    return this.delete('userRoles', data, null, userIds.join(','))
  }

  getRoleUsers (role) {
    return this.get('roleUsers', null, role)
  }

  getAllRoles (roleByType = false) {
    const data = {
      role_by_type: roleByType
    }
    return this.get('roles', data, null, false)
  }

  // KeyStone Tool
  getDV360AccessLLD (bucketName) {
    return this.postRaw('dv360AccessLLD', { 'bucket_name': bucketName })
  }
  getTTDAccessLLD (useScibidsFolder, partnerId, advertiserId, bucketAccess, useRedPrivacy) {
    return this.postRaw('TTDAccessLLD', {
      use_scibids_folder: useScibidsFolder,
      partner_id: partnerId,
      advertiser_id: advertiserId,
      bucket_access: bucketAccess,
      use_red_privacy: useRedPrivacy
    })
  }

  getMediaMathAccessLLD (organizationId, agencyId, advertiserId, level) {
    return this.postRaw('MediamathAccessLLD', {
      organization_id: organizationId,
      agency_id: agencyId,
      advertiser_id: advertiserId,
      level: level
    })
  }

  postReplayInstruStratError (dsp, level, ids) {
    return this.postRaw('ReplayInstruStratError', {
      dsp: dsp,
      level: level,
      ids: ids
    })
  }

  getAppnexusSplitAudience (memberId, ioTest, ioTemoin) {
    return this.get('AppnexusSplitAudience', {
      member_id: memberId,
      io_temoin: ioTemoin,
      io_test: ioTest
    })
  }
  putAppnexusSplitAudience (memberId, ioTest, ioTemoin, repartitionTest, repartitionTemoin) {
    return this.putRaw('AppnexusSplitAudience', {
      member_id: memberId,
      io_temoin: ioTemoin,
      io_test: ioTest,
      repartition_test: repartitionTest,
      repartition_temoin: repartitionTemoin
    })
  }
  putProjectConfigStatus (dsp, clientId, step, data = {}) {
    data['step'] = step
    let elementId = dsp + '/' + clientId
    return this.putRaw('projectConfigStatus', data, elementId)
  }
  getProjectConfigStatus (dsp, clientId) {
    let elementId = dsp + '/' + clientId
    return this.get('projectConfigStatus', null, elementId)
  }
  getServiceAccountCreation (dsp, clientId) {
    let elementId = dsp + '/' + clientId
    return this.get('serviceAccountCreation', null, elementId)
  }
  generateUrlServiceAccount (clientId) {
    return this.postRaw('serviceAccount', { 'client_id': clientId })
  }
  getTheTradeDeskLldVersions (clientId) {
    return this.get('TheTradeDeskLldVersions', null, clientId)
  }

  // DEMO CALL
  getDemos () {
    return this.get('demos')
  }

  putResetDemo (demoId) {
    return this.put('resetDemo', null, null, demoId)
  }

  postCreateDemo (demoName) {
    const data = {
      demo_name: demoName
    }
    return this.post('demos', data)
  }

  deleteDemo (demoId) {
    return this.delete('demo', null, demoId)
  }

  // keystone v2

  getKeystoneSiegeSettings (data = null) {
    return this.get('keystoneSiegeSettings', data)
  }

  postKeystoneSiegeSettings (data) {
    return this.postRaw('keystoneSiegeSettings', data)
  }

  putKeystoneSiegeSetting (data, id) {
    return this.putRaw('keystoneSiegeSetting', data, id)
  }

  getKeystoneMemberSettings (data = null) {
    return this.get('keystoneMemberSettings', data)
  }

  putKeystoneMemberSetting (data, id) {
    return this.putRaw('keystoneMemberSetting', data, id)
  }

  getKeystoneSiegeSetting (settingId) {
    return this.get('keystoneSiegeSetting', null, settingId)
  }

  getKeystoneMemberSetting (settingId) {
    return this.get('keystoneMemberSetting', null, settingId)
  }

  getCountries () {
    return this.get('countries')
  }

  getCollaboratorsOfSiege (siegeId) {
    return this.get('collaboratorsOfSiege', null, null, true, siegeId)
  }

  getAllCollaborators () {
    return this.get('collaborators')
  }

  getSiegeRulesOfSiege (siegeId) {
    return this.get('siegeRulesOfSiege', null, null, true, siegeId)
  }

  getAdvertisersOfMember (memberId, data = null) {
    return this.get('advertisersOfMember', data, null, true, memberId)
  }

  /**
   * @param searchData {{search?: string, siege_id?: string, id_list?: string}}
   * @param cancelTokenSource optional, make it available to be cancelled at any
   * @return {Promise<T>}
   */
  getAdvertisers (searchData, cancelTokenSource = null) {
    return this.get('advertisers', searchData, null, true, null, null, cancelTokenSource)
  }

  getAdvertiser (advertiserId) {
    return this.get('advertiser', null, advertiserId)
  }

  getSiegeRuleChecker (dsp, memberId, siegeId = null, advertiserIds = null, agencyIds = null, allBut = null) {
    let data = {
      member_id: memberId,
      dsp: dsp
    }

    if (siegeId) {
      data.siege_id = siegeId
    }

    if (advertiserIds) {
      data.advertiser_ids = advertiserIds
    } else if (agencyIds) {
      data.agency_ids = agencyIds
    }
    if (allBut) {
      data.all_but = allBut
    }
    return this.get('siegeRuleChecker', data)
  }

  getBriefOfExternalId (externalId, dsp) {
    const replaceIdObj = {
      external_id: externalId,
      dsp: dsp
    }
    return this.get('brief', null, null, true, null, replaceIdObj)
  }

  getAcknowledgments (briefId) {
    return this.get('acknowledgments', null, null, true, briefId)
  }

  /**
   * @param acknowledgmentIds {number[]}
   * @param isOk {boolean}
   * @return {Promise<T>}
   */
  setAcknowledgmentIsOk (acknowledgmentIds, isOk) {
    const data = {
      is_ok: isOk
    }
    return this.put('acknowledgment', data, null, acknowledgmentIds.join(','))
  }

  getInstructionNotes (ioId, dsp) {
    const replaceIdObj = {
      external_id: ioId,
      dsp: dsp
    }
    return this.get('instruNotes', null, null, true, null, replaceIdObj)
  }

  postInstructionNotes (ioId, dsp, content) {
    const replaceIdObj = {
      external_id: ioId,
      dsp: dsp
    }
    const data = {
      content: content
    }
    return this.post('instruNotes', data, null, null, replaceIdObj)
  }

  deleteInstruNote (instruNoteId) {
    return this.delete('instruNotes', null, instruNoteId)
  }

  /**
   * @param companyId {number}
   * @return {Promise<{data: Autoplugs[]}>}
   */
  getAutoplugsOfCompany (companyId) {
    return this.get('autoplugs', null, null, true, companyId)
  }

  /**
   * @param data {Autoplugs}
   * @return {Promise<T>}
   */
  postAutoplugs (data) {
    // eslint-disable-next-line camelcase
    const { company_id, ...d } = data
    return this.postRaw('autoplugs', d, null, company_id)
  }

  /**
   * @param data {Autoplugs}
   * @return {Promise<T>}
   */
  putAutoplug (data) {
    const { id, ...d } = data
    return this.putRaw('autoplug', d, null, id)
  }

  deleteAutoplug (autoplugId) {
    return this.delete('autoplug', null, autoplugId)
  }

  getStatusLogs (externalId) {
    return this.get('statusLogs', null, null, null, externalId)
  }

  // History Logs
  getLogsForEntity (entityId, entityType) {
    const replaceIdObj = {
      entity_id: entityId,
      entity_type: entityType
    }
    return this.get('logs', null, null, true, null, replaceIdObj)
  }

  // NEW FEATURE REFERENCE
  // GETS
  getNewFeatureReference (featureId) {
    return this.get('newFeatureReference', null, featureId)
  }
  getNewFeatureReferencesMembers (featureId) {
    return this.get('newFeatureReferenceMembers', null, null, true, featureId)
  }
  getNewFeatureReferences (onlyDevNames = false) {
    const data = {
      only_dev_names: onlyDevNames
    }
    return this.get('newFeatureReferences', data, null)
  }
  getNewFeatureReferenceUsageForEntity (devName, application, entityId) {
    const replaceIdObj = {
      application: application,
      entity_id: entityId,
      dev_name: devName
    }
    return this.get('newFeatureReferenceUsageForEntity', null, null, false, null, replaceIdObj)
  }

  // POSTS
  createNewFeatureReferences (data) {
    return this.postRaw('newFeatureReferences', data)
  }
  updateNewFeatureReference (featureData) {
    const { id, ...d } = featureData
    return this.putRaw('newFeatureReference', d, null, id)
  }
  /**
   * Get new features for specific entity for said dsp (used in Keystone tabs to activate or deactivate the features.
   * @param entityId if member, fill with the keystoneMemberSetting.id, not member.id
   * @param application
   * @param dsp
   * @returns {Promise<{data: NewFeatureReferenceApiArgs[]}>}
   */
  getNewFeatureReferencesForEntityWithDsp (entityId, application, dsp) {
    const replaceIdObj = {
      entity_id: entityId,
      application: application,
      dsp: dsp
    }
    return this.get('newFeatureReferencesForEntityWithDsp', null, null, true, null, replaceIdObj)
  }

  getUsersnapCredentials () {
    return this.get('usersnap_credentials')
  }
  // BULK ADD ALERTING
  getBulkAddBriefs (bulkAddId) {
    return this.get('bulkAddBriefs', null, null, true, bulkAddId)
  }

  /**
   * @param externalId
   * @param dsp
   * @returns {Promise<{data: OverviewAttribute[]}>}
   */
  getOverviewAttributes (externalId, dsp) {
    return this.getObj('overviewAttributes', {
      external_id: externalId,
      dsp: dsp
    })
  }

  getCheckPreMepResult (externalId, subDsp) {
    return this.getObj('checkPreMepResult', {
      external_id: externalId,
      sub_dsp: subDsp
    })
  }

  // THIRD PARTY
  getThirdPartyFeeds () {
    return this.get('thirdPartyFeeds')
  }

  getThirdPartyFeed (data) {
    return this.get('thirdPartyFeed', data)
  }

  postThirdPartyFeed (data) {
    return this.postRaw('thirdPartyFeed', data)
  }

  postSetupTransformations (data) {
    return this.postRaw('setupTransformations', data)
  }

  postValidateThirdPartySql (data) {
    return this.postRaw('validateThirdPartySql', data)
  }

  postThirdPartySimulate (data) {
    return this.postRaw('thirdPartySimulate', data)
  }

  postThirdPartyTransformations (data) {
    return this.postRaw('thirdPartyTransformations', data)
  }

  postActivateThirdPartyFeed (data) {
    return this.postRaw('activateThirdPartyFeed', data)
  }

  getThirdPartyLocation (data) {
    return this.get('thirdPartyLocation', data)
  }

  postThirdPartyFile (data) {
    return this.postRaw('thirdPartyFile', data)
  }

  getThirdPartyRefGranularity (data) {
    return this.get('thirdPartyRefGranularity', data)
  }

  getThirdPartyRefEvents (data) {
    return this.get('thirdPartyRefEvents', data)
  }

  getThirdPartyRefEventTypes () {
    return this.get('thirdPartyRefEventTypes')
  }

  getBaseline (overviewId) {
    return this.getObj('baseline', {
      overview_id: overviewId
    })
  }

  putBaseline (overviewId, data) {
    return this.putRaw('baseline', data, null, overviewId)
  }

  /**
   * @param externalIds
   * @param subDsp {InstructionDsp}
   * @param aiPerf {AiPerformance | null}
   * @param baselineType {BaselineType | null}
   * @returns {Promise<_.LoDashFp.T>}
   */
  getBaselines (externalIds,
    subDsp,
    aiPerf = null,
    baselineType = null) {
    let data = {
      external_ids: externalIds.join(','),
      sub_dsp: subDsp
    }

    if (aiPerf) {
      data.ai_performance = aiPerf
    }

    if (baselineType) {
      data.baseline_type = baselineType
    }

    return this.get('baselines', data)
  }

  getPivotVariables (subDsp) {
    return this.getObj('pivotVariables', {
      sub_dsp: subDsp
    })
  }

  postRemoveScibids (dsp, memberExternalId, ioID, data) {
    const replaceIdObj = {
      dsp: dsp,
      member_ext_id: memberExternalId,
      io_id: ioID
    }
    return this.postRaw('removeScibids', data, null, null, replaceIdObj)
  }

  postCreateInsight (data) {
    return this.postRaw('create_insight', data)
  }

  postCreateDeckABTest (data) {
    return this.postRaw('createDeckABTest', data)
  }

  postCreateTroubleshooting (data) {
    return this.postRaw('createTroubleshooting', data)
  }

  getEventsOfIo (overviewId) {
    return this.getObj('eventsOfIo', {
      overview_id: overviewId
    })
  }

  /**
   * @param data {DspInstruction[]}
   * @param subDsp {InstructionDsp}
   * @returns {Promise<_.LoDashFp.T>}
   */
  getCheckPreMepInstructionsValidator (data, subDsp) {
    const replaceIdObj = {
      sub_dsp: subDsp
    }
    return this.postRaw('checkPreMepInstructionsValidator', data, null, null, replaceIdObj)
  }

  getProactiveIo (proactiveIoId) {
    const res = this.get('proactive_io', null, proactiveIoId)
    return res
  }
  getProactiveIos () {
    const res = this.get('proactive_ios')
    return res
  }

  formatProactiveIoData (clientName, holding, company, siege, advertiser) {
    const data = {
      client_name: clientName,
      holding: null,
      company: null,
      siege: null,
      advertiser: null
    }
    if (holding) {
      data['holding'] = holding
    } else if (company) {
      data['company'] = company
    } else if (siege) {
      data['siege'] = siege
    } else if (advertiser) {
      data['advertiser'] = advertiser
    }
    return data
  }
  postProactiveIos (clientName, holding, company, siege, advertiser, comment) {
    const data = this.formatProactiveIoData(clientName, holding, company, siege, advertiser)
    data['comments'] = comment
    return this.postRaw('proactive_ios', data)
  }

  putProactiveIos (clientName, holding, company, member, advertiser, idProactiveIo) {
    const data = this.formatProactiveIoData(clientName, holding, company, member, advertiser)
    return this.putRaw('proactive_io', data, idProactiveIo)
  }

  deleteProactiveIos (proactiveIo) {
    return this.delete('proactive_io', null, proactiveIo)
  }

  getProactiveIosComments (idProactiveIo) {
    return this.getObj('proactive_io_comments', { proactive_io_id: idProactiveIo })
  }
  postProactiveIoComments (idProactiveIo, comment, author) {
    const data = { comment: comment, author: author }
    const insertId = {
      proactive_io_id: idProactiveIo
    }
    return this.postRaw('proactive_io_comments', data, null, null, insertId)
  }
  putProactiveIoComment (idProactiveIo, idProactiveIoComment, comment, author) {
    const data = { comment: comment, author: author }
    const replaceIdObj = {
      proactive_io_id: idProactiveIo,
      comment_id: idProactiveIoComment
    }
    return this.put('proactive_io_comment', data, null, null, replaceIdObj)
  }
  deleteProactiveIoComment (idProactiveIo, idProactiveIoComment) {
    const replaceIdObj = {
      proactive_io_id: idProactiveIo,
      comment_id: idProactiveIoComment
    }
    return this.delete('proactive_io_comment', null, null, null, replaceIdObj)
  }

  getUserRightsOnEntity (entityType, entityId, pagination) {
    const replaceIdObj = {
      entity_type: entityType,
      entity_id: entityId
    }
    return this.getObj('userRightsOnEntity', replaceIdObj, null, pagination)
  }

  getUserRightsOfUser (userMail, pagination) {
    return this.getObj('userRightsOfUser', { user_mail: userMail }, null, pagination)
  }

  // ALLOWED AUTH DOMAINS
  getAllowedAuthDomains () {
    return this.get('allowed_auth_domains')
  }
  getOrgaAllowedAuthDomains (orga = None) {
    return this.getObj('orga_allowed_auth_domains', { orga: orga })
  }

  getColdStartAvailability (overviewId) {
    return this.getObj('coldStartAvailability', { overview_id: overviewId })
  }

  /**
   * @param paramsOverwritesBulkInstructions {ParamsOverwritesBulkInstructions}
   * @param subDsp {InstructionDsp}
   * @returns {Promise<_.LoDashFp.T>}
   */
  postOverwritesBulkInstructions (paramsOverwritesBulkInstructions, subDsp) {
    return this.postRaw('overwritesBulkInstructions', paramsOverwritesBulkInstructions, null, null, {
      sub_dsp: subDsp
    })
  }

  /**
   * @param paramsOverwritesBulkInstructions {ParamsOverwritesBulkInstructions}
   * @param subDsp {InstructionDsp}
   * @returns {Promise<_.LoDashFp.T>}
   */
  postSimulateOverwritesBulkInstructions (paramsOverwritesBulkInstructions, subDsp) {
    return this.postRaw('simulateOverwritesBulkInstructions', paramsOverwritesBulkInstructions, null, null, {
      sub_dsp: subDsp
    })
  }

  getCampaigns (params, cancelTokenSource = null) {
    return this.get('campaigns', params, null, true, null, null, cancelTokenSource)
  }

  getSurcoucheV2InsertionOrder (dsp, insertionOrderID, cancelTokenSource = null) {
    const replaceIdObj = {
      dsp: dsp,
      insertion_order_id: insertionOrderID
    }
    return this.get('surcoucheV2Instruction', null, null, true, null, replaceIdObj, cancelTokenSource)
  }

  getSurcoucheV2InsertionOrders (params, cancelTokenSource = null) {
    return this.get('surcoucheV2Instructions', params, null, true, null, null, cancelTokenSource)
  }

  getSurcoucheForcedRatio (overviewExternalID, dsp, memberExternalID, transactionID) {
    const queryParams = {
      dsp: dsp,
      overview_external_id: overviewExternalID,
      member_external_id: memberExternalID,
      transaction_id: transactionID
    }
    return this.getObj('surcoucheForcedRatio', queryParams)
  }

  getSurcoucheForcedRatios (dsp, memberExternalID, overviewExternalID) {
    const queryParams = {
      dsp: dsp,
      member_external_id: memberExternalID,
      overview_external_id: overviewExternalID
    }
    return this.getObj('surcoucheForcedRatios', queryParams)
  }

  postNewSurcoucheForcedRatio (dsp, memberExternalID, overviewExternalID, data) {
    const replaceMulti = {
      dsp: dsp,
      member_external_id: memberExternalID,
      overview_external_id: overviewExternalID
    }
    return this.post('surcoucheForcedRatios', data, null, null, replaceMulti)
  }

  getSurcoucheForcedRatioAuthorizedDsps (dsp) {
    return this.get('surcoucheForcedRatioAuthorizedDsps')
  }

  getSurcoucheForcedRatioDspConfig (dsp) {
    return this.getObj('surcoucheForcedRatioDspConfig', { dsp: dsp })
  }

  putSurcoucheForcedRatioActivation (dsp, memberExternalID, overviewExternalID) {
    const replaceMulti = {
      dsp: dsp,
      member_external_id: memberExternalID,
      overview_external_id: overviewExternalID
    }
    return this.put('surcoucheForcedRatioActivation', null, null, null, replaceMulti)
  }

  getKpiTypes () {
    return this.get('kpiTypes')
  }

  getAvailableKpis (overviewId, canBeUsedAsConstraints = null) {
    let data = null
    if (canBeUsedAsConstraints) {
      data = {
        can_be_used_as_a_constraint: canBeUsedAsConstraints
      }
    }
    return this.getObj('availableKpis', { overview_id: overviewId }, true, data)
  }

  getAlertCategories () {
    return this.get('alertCategories')
  }

  getBriefFieldsConfig () {
    return this.get('briefFieldsConfig', null, null, false)
  }

  /**
   * @param externalIds {string[]}
   * @param subDsp {InstructionDsp}
   * @param futureOptiOttoValue {Boolean}
   * @param futureOttoMinVizValue {Boolean}
   * @param recency (day) {number}
   * @param cancel {Boolean}
   * @returns {Promise<T>}
   */
  postSimulateExplode (externalIds, subDsp, futureOptiOttoValue, futureOttoMinVizValue, recency = 7, cancel = false) {
    const data = {
      external_ids: externalIds,
      future_opti_otto_value: futureOptiOttoValue,
      future_otto_min_viz_value: futureOttoMinVizValue,
      sub_dsp: subDsp,
      recency: recency
    }
    return this.postRaw('simulateExplode', data, null, null, null, cancel)
  }

  getInstructionLogs (dsp, ioId, onlyChanges = false) {
    const data = {
      only_changes: onlyChanges
    }
    return this.getObj('instructionLogs', { dsp: dsp, io_id: ioId }, true, data)
  }

  postBulkOtto (externalIds, subDsp, otto, ottoMinViz, explode = false) {
    const data = {
      io_ids: externalIds,
      opti_auto: otto,
      otto_min_viz: ottoMinViz,
      explode_the_instructions: explode
    }
    return this.postRaw('bulkOtto', data, null, null, {
      sub_dsp: subDsp
    })
  }

  postSimulateBulkOtto (externalIds, subDsp, otto, ottoMinViz, explode = false, cancel = false) {
    const data = {
      io_ids: externalIds,
      opti_auto: otto,
      otto_min_viz: ottoMinViz,
      explode_the_instructions: explode
    }
    return this.postRaw('simulateBulkOtto', data, null, null, {
      sub_dsp: subDsp
    }, cancel)
  }

  // IO Page

  getIoPageOverview (dsp, ioId) {
    return this.getObj('ioPageOverview', { dsp: dsp, external_id: ioId })
  }

  getIoPagePerformance (dsp, ioId) {
    return this.getObj('ioPagePerformance', { dsp: dsp, external_id: ioId })
  }

  getComplementaryKpiType (kpiType) {
    return this.getObj('complementaryKpiType', { kpi_type: kpiType })
  }
}

export default ScibidsApiCaller
