import vuetify from './plugins/vuetify'
import App from './App.vue'
import VueRouter from 'vue-router'
import { router, authModule } from './router'
import {
  dspConfig,
  $YOUTUBE,
  $MEDIAMATH,
  $DBM,
  $BEESWAX,
  $APPNEXUS,
  $THETRADEDESK,
  $FACEBOOK,
  $OPTIMIZE_ADGROUPS,
  $OPTIMIZE_ADSETS,
  $OPTIMIZE_CAMPAIGNS,
  $OPTIMIZE_LINEITEMS,
  $OPTIMIZE_STRATEGIES,
  $KAYZEN, $META
} from '../config/dspConfig'
import { alertsCode, getInfoErrorType, getInfoError } from '../config/alertsCode.js'
import { setQueryStringAlertings, setQueryStringKeystone } from '../utils/queryString'
import store, { Vue, NORMAL, HAVE_JUST_DONE_BULK, FORM_IS_OPEN, EDIT, NEW, apiCaller, DRAFT, TO_VALIDATE, VALIDATED, REMOVED } from '../store'
import VueUtils from '../utils/vueUtils.js'
// @ts-ignore
import styles from '../src/assets/css-modules/styled-overflow.module.css'
import { pageGuard, pageGuardTester } from '../config/pageGuard'
import * as commonUtils from '../utils/commonUtils'
import { surcoucheConfig } from './loadConfig'
import ScibidsApiCaller from '../utils/Api/scibidsApiCaller'
import SurcoucheConfig from 'config/SurcoucheConfig'
import { alertings } from '../store/modules/alertings'
import PlausibleHelper from '../utils/Plausible/plausible_helper'
import { processHostEnv } from '../envHelper'

const env = process.env.NODE_ENV

console.log(process.env)

/** Init Plausible (analytics) **/
const plausibleHelper = new PlausibleHelper()
/** Augment Vue declaration **/

declare module 'vue/types/vue' {
  // 3. Declare augmentation for Vue

  interface Vue {
    $apiCaller: ScibidsApiCaller,
    $router: typeof router,
    $commonUtils: typeof commonUtils,
    $authModule: typeof authModule,
    $surcoucheConfig: SurcoucheConfig,
    $plausibleHelper: PlausibleHelper,
    $getInfoError: (errorType: string) => AlertConfigInfo
    $_FRONT_HOST: string,
    $dspConfig: typeof dspConfig,
    $_ENV: 'development' | 'production'
    $_VUE_ENV: 'development' | 'production' | 'staging'
  }
}

declare global {
  interface Window {
    onUsersnapCXLoad : any
  }
}
// Vue is imported from store/index.js

Vue.prototype.$authModule = authModule

Vue.use(VueUtils)
Vue.use(VueRouter)
Vue.use(styles)

Vue.config.productionTip = false

Vue.prototype.$surcoucheConfig = surcoucheConfig
Vue.prototype.$isOnMaintenance = false

Vue.prototype.$apiCaller = apiCaller
Vue.prototype.$commonUtils = commonUtils
Vue.prototype.$plausibleHelper = plausibleHelper
// Global vue value
Vue.prototype.$_ENV = env // development | production, getted from NODE_ENV (see : vue.config.js)
Vue.prototype.$_VUE_ENV = process.env.VUE_APP_ENV
Vue.prototype.$_API_HOST = processHostEnv(process.env.VUE_APP_API_HOST)
Vue.prototype.$_FRONT_HOST = processHostEnv(process.env.VUE_APP_FRONT_HOST)
Vue.prototype.$dspConfig = dspConfig
Vue.prototype.$alertsCode = alertsCode
Vue.prototype.$getInfoErrorType = getInfoErrorType
Vue.prototype.$getInfoError = getInfoError

// debug
Vue.prototype.$debug = function (elem: any) {
  console.log('debug', elem)
}

//
Vue.prototype.$APPNEXUS = $APPNEXUS
Vue.prototype.$MEDIAMATH = $MEDIAMATH
Vue.prototype.$DBM = $DBM
Vue.prototype.$THETRADEDESK = $THETRADEDESK
Vue.prototype.$YOUTUBE = $YOUTUBE
Vue.prototype.$BEESWAX = $BEESWAX
Vue.prototype.$FACEBOOK = $FACEBOOK
Vue.prototype.$KAYZEN = $KAYZEN
Vue.prototype.$META = $META

Vue.prototype.$OPTIMIZE_CAMPAIGNS = $OPTIMIZE_CAMPAIGNS
Vue.prototype.$OPTIMIZE_LINEITEMS = $OPTIMIZE_LINEITEMS
Vue.prototype.$OPTIMIZE_ADGROUPS = $OPTIMIZE_ADGROUPS
Vue.prototype.$OPTIMIZE_STRATEGIES = $OPTIMIZE_STRATEGIES
Vue.prototype.$OPTIMIZE_ADSETS = $OPTIMIZE_ADSETS

// contain state const from store
// it represent the state of the app
Vue.prototype.$NORMAL = NORMAL
Vue.prototype.$HAVE_JUST_DONE_BULK = HAVE_JUST_DONE_BULK
Vue.prototype.$FORM_IS_OPEN = FORM_IS_OPEN
// it represent the status of the form
Vue.prototype.$EDIT = EDIT
Vue.prototype.$NEW = NEW

Vue.prototype.$DRAFT = DRAFT
Vue.prototype.$TO_VALIDATE = TO_VALIDATE
Vue.prototype.$VALIDATED = VALIDATED
Vue.prototype.$REMOVED = REMOVED

// TIME UNIT CONSTANT
// number of minute in a day, used for computed PCwindowWithUnit and PVwindowWithUnit
Vue.prototype.$MINUTES_IN_A_DAY = 1440
Vue.prototype.$ONE_MINUTE_IN_MILLISECONDS = 60000

Vue.prototype.$watchValue = (value: any) => console.warn(value)

Vue.prototype.$minutesMilliseconds = function (m: number) {
  return m * this.$ONE_MINUTE_IN_MILLISECONDS
}

Vue.prototype.$millisecondsInMinutes = function (m: number) {
  return m / this.$ONE_MINUTE_IN_MILLISECONDS
}

// Some globals Vue function

/**
 * @param route : {string} the route to go
 */
Vue.prototype.$changeRoute = function (route: string) {
  this.$router.push(route)
}

Vue.prototype.$setQueryStringAlertings = setQueryStringAlertings
Vue.prototype.$setQueryStringKeystone = setQueryStringKeystone

/**
 * Add function ucwords to all string
 * classic uppercase function
 */
/* eslint-disable no-extend-native */
// @ts-ignore
String.prototype.ucwords = function () {
  let str = this.toLowerCase()
  return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
    function (s) {
      return s.toUpperCase()
    })
}
/* eslint-enable no-extend-native */

/**
 * allow access to the routes depending of the environment (see config/pageGuard.js)
 */
Vue.prototype.$pageGuard = pageGuard
Vue.prototype.$pageGuardTester = pageGuardTester

// we must register alertings store directly because of the initializeStore function
if (!store.state.alertings) store.registerModule('alertings', alertings)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate () {
    this.$store.commit('initializeStore')
  }
}).$mount('#app')
