<template>
  <v-app>
    <Usersnap :authenticated="authentified"/>
    <TopNav
    :version="version"
    v-on:change-app="changeApp = true"
    >
    </TopNav>
    <SideNav
    v-on:nav-change="matchSizeAfterWait"
    >
    </SideNav>

    <div v-if="!displayRouterView">
      <WaitBar
      :message="waitBarMessage"
      >
      </WaitBar>
    </div>

    <router-view
      v-on:authentified="authentified = true"
      v-on:ask-snackbar="addToSnackBarItems"
      v-on:ask-bottom-snackbar="activeBottomSnackBar"
      v-on:app-changed-success="changeApp = false"
      v-on:ask-relog="askRelog"
      v-on:error-surcouche-config="askRelog"
      v-show="displayRouterView"
      :style="styleRouteBox" id='router-view-box'>
    </router-view>

    <QueueSnackBar
      :items="snackBarItems"
    >

    </QueueSnackBar>

    <v-snackbar
      v-model="bottomSnackBar"
      :bottom="true"
      :left="true"
      :color="bottomSnackBarColor"
    >
      {{ bottomSnackBarMessage }}
      <v-btn
        :color="bottomSnackBarColor === 'black' ? 'pink' : 'white'"
        text
        @click="bottomSnackBar = false"
      >
        Close
      </v-btn>
    </v-snackbar>

  </v-app>
</template>

<script>
import { SideNav, TopNav } from '../src/components/Nav'
import WaitBar from './components/Common/WaitBar'
import QueueSnackBar from './components/Common/QueueSnackBar'
import { setTimeout } from 'timers'
import { Autologout } from '../utils/autologout.js'
import store from '../store/index.js'
import { common } from '../store/modules/common'
import { users } from '../store/modules/users'
import { mapGetters, mapMutations } from 'vuex'
import Usersnap from '@/components/Usersnap/Usersnap'

// load the store module dynamically only when needed
if (!store.state.users) store.registerModule('users', users)
if (!store.state.common) store.registerModule('common', common)

export default {
  name: 'app',
  data: function () {
    return {
      authentified: false,
      changeApp: false,
      version: '1.28.13',
      routeBoxMarginLeft: 'margin-left:250px', // default, checked at every update
      routerHight: 'height:517px',
      substractToRouterHight: 70,
      bottomSnackBar: false,
      bottomSnackBarColor: 'black',
      bottomSnackBarMessage: '',
      snackBarItems: [],
      isOnMaintenance: false,
      configLoaded: false
    }
  },
  components: {
    Usersnap,
    SideNav,
    TopNav,
    WaitBar,
    QueueSnackBar
  },
  methods: {
    ...mapMutations(['errorMessageHasBeenSend', 'successMessageHasBeenSend']),
    matchSizeAfterWait: function () {
      setTimeout(() => {
        this.matchSize()
        // WIP
        this.matchRouterOverflow()
      }, 300)
    },
    matchSize: function () {
      let widthSideNav = this.$children[0].$children
      for (let key in widthSideNav) {
        let name = widthSideNav[key].$options.name
        if (name === 'SideNav') {
          this.routeBoxMarginLeft = 'margin-left:' + widthSideNav[key].$el.style.width
        }
      }
    },
    matchRouterOverflow () {
      let heightToSet = window.innerHeight - this.substractToRouterHight
      this.routerHight = heightToSet + 'px'
    },
    activeBottomSnackBar (message, success = true) {
      this.bottomSnackBar = true
      this.bottomSnackBarMessage = message
      this.bottomSnackBarColor = success ? 'black' : 'red'
    },
    onResize (event) {
      this.matchRouterOverflow()
    },
    disableMousewheelEventOnInputNumber () {
      document.addEventListener('mousewheel', function (event) {
        if (document.activeElement.type === 'number') {
          document.activeElement.blur()
        }
      })
    },
    setTimerBeforeRefreshingAlert () {
      let autologout = new Autologout()

      autologout.init(function () {
        alert('You have been inactive for 120 minutes. Your session has expired and the application will refresh.')
        window.location.reload()
      })
    },
    addToSnackBarItems (message, status = 'success', path = '', pathLabel = '', pathAction = null) {
      this.snackBarItems.push({
        message: message,
        status: status,
        path: path,
        pathLabel: pathLabel,
        pathAction: pathAction
      })
    },
    /**
     * Method you can call when Api return status 401 unauthorized
     * This can happen when cookie is here but jwt is expired
     * Ask for logout to the user, for reset security system
     */
    askRelog () {
      const pathAction = () => {
        this.$authModule.destroyJwt()
        let routePath = this.$route.path

        if (routePath === '/') {
          this.$router.go()
        } else {
          this.$router.replace('/')
        }
      }
      this.addToSnackBarItems(
        'Problem with token, please click on relog button for being relogged',
        '401',
        '/',
        'Relog',
        pathAction)
    },
    askRelogTokenExpireSoon () {
      const pathAction = () => {
        this.$authModule.refreshToken()
      }
      this.addToSnackBarItems(
        'Your token will expire soon, please click on relog button for being relogged and avoid disconnection.',
        '',
        '/',
        'Relog',
        pathAction)
    }
  },
  created: async function () {
    if (!this.$surcoucheConfig.isConfigLoaded) {
      this.$surcoucheConfig.refreshConfig()
        .then((result) => {
          this.configLoaded = true
          this.isOnMaintenance = this.$surcoucheConfig.getters.ON_MAINTENANCE

          if (!result && this.authentified) {
            this.askRelog()
          }
        })
    }
    window.addEventListener('resize', this.onResize)
    this.disableMousewheelEventOnInputNumber()
    this.setTimerBeforeRefreshingAlert()
  },
  mounted: function () {
    let vm = this
    this.matchRouterOverflow()
    this.matchSize()
    vm.$nextTick(function () {
      this.matchSize()
      this.matchRouterOverflow()
    })
  },
  computed: {
    ...mapGetters(['isUserApiLoading', 'getAskErrorMessage', 'getErrorMessage', 'getAskSuccessMessage', 'getSuccessMessage', 'getAskRelog']),
    styleRouteBox: function () {
      return this.routeBoxMarginLeft + ';'
    },
    waitBarMessage: function () {
      if (this.authentified === false) {
        return `Authentication process...`
      }

      if (this.changeApp) {
        return `Loading...`
      }

      if (this.isUserApiLoading) {
        return `Load users data...`
      }

      if (this.$surcoucheConfig.isLoading) {
        return `Load config...`
      }

      return ''
    },
    displayRouterView: function () {
      return (!this.changeApp && !this.isUserApiLoading && this.$surcoucheConfig.isConfigLoaded && this.authentified) ||
        (this.configLoaded && this.isOnMaintenance)
    }
  },
  watch: {
    getAskErrorMessage: function (value) {
      if (value) {
        this.addToSnackBarItems(this.getErrorMessage, 'error')
        this.errorMessageHasBeenSend()
      }
    },
    getAskSuccessMessage: function (value) {
      if (value) {
        this.addToSnackBarItems(this.getSuccessMessage, 'success')
        this.successMessageHasBeenSend()
      }
    },
    'getAskRelog': {
      deep: true,
      immediate: true,
      handler: function (askRelog) {
        if (askRelog) {
          this.askRelog()
          this.$store.commit('setAskRelog', false)
        }
      }
    },
    isOnMaintenance: {
      immediate: true,
      handler: function (value) {
        this.$isOnMaintenance = value
        if (value) {
          this.$router.push({ path: '/maintenance' })
        }
      }
    },
    $route: {
      immediate: true,
      handler: function (to, from) {
        if (this.$route.name) {
          /** plausible (analytics) **/
          const userStatus = this.$store.getters.getUserStatus
          if (this.$store.getters.isUserStatusSet) {
            this.$plausibleHelper.registerPlausibleRole(userStatus)
          } else {
            console.warn('User status not set, plausible role not registered')
          }
          /** check lifetime of jwt **/
          const _7_MINUTES = 7
          if (this.$authModule.tokenWillExpireIn(_7_MINUTES)) {
            this.askRelogTokenExpireSoon()
          }
        }
      }
    }
  }
}
</script>

<style>
  html {
    font-size: 14px;
  }
  .pixel-v-menu {
    background: white;
    padding: 1em;
    font-size: 9px;
  }
  .mini-info-text {
    color: gray;
    font-style: italic;
    font-size: 7px;
    cursor: pointer;
    padding-bottom: 1em;
  }

  #search-input-box {
    padding: 2em;
  }
  #router-view-box {
    margin-top: 40px;
    margin-left: 80px;
    overflow-x: auto;
  }
  .v-table__overflow {
    font-size: 8px !important;
    overflow-x: unset !important;
    overflow-y: unset !important;
  }

  /* add this class to a element to disabled the selection  */
  .noselect,
          i {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }

  .v-snack__wrapper.error {
    height: 10em;
  }

  .v-snack__wrapper.error .v-snack__content   {
    height: 100%;
  }

  .v-snack__wrapper.success .v-snack__content {
    height: auto;
  }

  .neutral-div {
    display: unset;
  }

.styled-shadow-hover {
    transition: 0.5s;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.15) !important;
}

.styled-shadow-hover:hover {
    box-shadow: 0rem 0.5rem 2rem rgba(0, 0, 0, 0.15) !important;
}

.gmail-like-hover {
  transition: 0.1s !important;
}

.gmail-like-hover:hover {
    -webkit-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    z-index: 1;
}

.gmail-like {
  -webkit-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  z-index: 1;
}

.cloud-translate3D {
    border: transparent solid 1px;
    display: block;
    margin: 0;
    padding: 23px;
    position: relative;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.cloud-translate3D:hover {
    border-color: #2b85e7;
    border-radius: 4px;
    -webkit-box-shadow: 0 8px 8px rgba(1,67,163,.24), 0 0 8px rgba(1,67,163,.12), 0 6px 18px rgba(43,133,231,.12);
    box-shadow: 0 8px 8px rgba(1,67,163,.24), 0 0 8px rgba(1,67,163,.12), 0 6px 18px rgba(43,133,231,.12);
    -webkit-transform: translate3d(0,-2px,0);
    transform: translate3d(0,-2px,0);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-deactivated {
  cursor: not-allowed;
}

.respect-new-line {
  white-space: pre-wrap;
}

/* FOR MATERIALS ICON */
/* https://github.com/vuetifyjs/vuetify/issues/4164 */
/* https://fonts.googleapis.com/css?family=Material+Icons%7CMaterial+Icons+Outlined%7CMaterial+Icons+Two+Tone%7CMaterial+Icons+Round%7CMaterial+Icons+Sharp */

  /* fallback */
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }
  /* fallback */
  @font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v8/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
  }
  /* fallback */
  @font-face {
    font-family: 'Material Icons Round';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconsround/v8/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmP.woff2) format('woff2');
  }
  /* fallback */
  @font-face {
    font-family: 'Material Icons Sharp';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconssharp/v9/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvR.woff2) format('woff2');
  }
  /* fallback */
  @font-face {
    font-family: 'Material Icons Two Tone';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconstwotone/v7/hESh6WRmNCxEqUmNyh3JDeGxjVVyMg4tHGctNCu0.woff2) format('woff2');
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .material-icons-round {
    font-family: 'Material Icons Round';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .material-icons-sharp {
    font-family: 'Material Icons Sharp';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .material-icons-two-tone {
    font-family: 'Material Icons Two Tone';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  /**
    * Gmail like scroll bar
    */
  .styled-scrollbar ::-webkit-scrollbar {
    background: transparent;
    overflow: visible;
    width: 10px;
    height: 10px;
  }

  .styled-scrollbar ::-webkit-scrollbar:hover {
    background: #0d47a1 !important;
  }

  .styled-scrollbar ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    /*border: solid whitesmoke;*/
  }

  .styled-scrollbar ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  .styled-scrollbar ::-webkit-scrollbar-thumb:horizontal {
    border-width: 4px 6px;
    min-width: 40px;
  }

  .styled-scrollbar ::-webkit-scrollbar-thumb:vertical {
    border-width: 4px 6px;
    min-height: 40px;
  }

  /*.styled-scrollbar ::-webkit-scrollbar-track-piece {*/
  /*  background-color: white;*/
  /*}*/

  .styled-scrollbar ::-webkit-scrollbar-track:hover {
    background-color: whitesmoke !important;
  }

  .styled-scrollbar ::-webkit-scrollbar-thumb:hover ~ ::-webkit-scrollbar-track-piece {
    background-color: whitesmoke;
  }

  .styled-scrollbar ::-webkit-scrollbar-corner {
    background: transparent;
  }

  .styled-scrollbar ::-webkit-scrollbar-thumb {
    background-color: #ADADAD;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07);
  }

  .styled-scrollbar ::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }

  .styled-scrollbar ::-webkit-scrollbar-track:vertical:hover {
    background-color: #000;
  }

  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .content-visibility-auto {
    content-visibility: auto;
  }

  /** strat_lvl style */

  .strat-lvl.standard {
    color: rgb(84 84 84);
    opacity: 0.5;
  }

  .strat-lvl.under_monitoring {
    color: yellow;
    opacity: 0.4;
  }

  .strat-lvl.strat {
    color: yellow;
    opacity: 0.7;
  }

  .strat-lvl.ultra_strat {
    color: yellow;
    opacity: 1;
  }

  /*
  ** When we open modal sometimes in firefox we can scroll in background of this one.
  ** To fix that we decide to target on the v-dialog class of vuetify.
  ** When this one is active we fixed position.
  */
  html:has(.v-dialog--active) {
    overflow: hidden !important;
  }

</style>
