import DspConfig from './DspConfigModel/DspConfig'
import { toMergeCommonMarkupModule, toMergeCommonCustomProdMgmt } from './commonDspConfig'
import { KpiValue } from '../../types/brief_enum'

export default new DspConfig({
  headers: [
    { text: 'info', value: 'group_name', sortable: false, onlyHeader: true },
    { text: 'Group Name', value: 'group_name', class: 'td-data-table-left' },
    { text: 'Member / Advertiser', value: 'advertiser_id', sortable: false, class: 'td-data-table-center' },
    { text: 'IO/LI/CA', value: '', sortable: false, class: 'td-data-table-center' },
    { text: 'Objectives', value: '', sortable: false, class: 'td-data-table-left' }
  ],
  client_value: 'partner_id',
  ioField: 'optimize_campaigns',
  newIoField: 'campaign_id',
  ioFieldLabel: 'Campaign Id',
  ioFieldAlert: 'campaign_id',
  adLabel: 'Advertiser id',
  adField: 'advertiser_id',
  matchingFields: [],
  specialFields: [],
  kpiToOptimizeOptions: [
    KpiValue.reach,
    KpiValue.CPM,
    KpiValue.CPV,
    KpiValue.CPA
  ],
  constraintsFormConfig: [],
  overwritingFields: {
    normal: [
      { label: 'Overwrite Campaign Budget', value: 'overwrite_campaign_budget' }
    ],
    advanced: [
    ]
  },
  toMerge: []
})
